import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../package/ui.common"
import {
  updateRefreshLoad,
  updateUserAssets,
  updateUserAssetsLastFetch,
} from "../package/rx.core/src/redux/features"
import { AssetForTriaName, RootState } from "../package/rx.core"
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext"

export const useFetchAssets = () => {
  const dispatch = useDispatch()
  const { getAssetsForATriaName, getAssetDetails } = useTriaUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triaName, setTriaName] = useState<string>()
  const lastFetchTime = useSelector(
    (store: RootState) => store?.User?.userAssetsLastFetch
  )
  const assetsDataExists = useSelector(
    (store: RootState) => store.User?.userAssets
  )
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  )
  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  )
  const userPrefs = useSelector((store: RootState) => store?.userpref)
  const { reload } = useReload()

  useEffect(() => {
    if (userPrefs) {
      console.log(userPrefs)
    }
  }, [userPrefs])

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchAssetsForATriaName = async () => {
    const now = new Date()
    if (
      !lastFetchTime ||
      (now.getTime() - new Date(lastFetchTime).getTime() >= 10000 &&
        !userInactive) ||
      reload
    ) {
      try {
        if (!assetsDataExists) {
          setIsLoading(true)
        }

        const val = await getAssetsForATriaName(triaName || "")
        const assets = val as AssetForTriaName[]

        if (userPrefs?.customChains && userPrefs?.customTokens) {
          const customChainDetails = (
            await Promise.all(
              userPrefs.customChains.map(async (chain) => {
                const tokensForChain = userPrefs.customTokens.filter(
                  (token) => token.chainId === chain.chainId
                )

                const tokenDetails = await Promise.all(
                  tokensForChain.map((token) =>
                    getAssetDetails({
                      tokenAddress: token.tokenAddress,
                      chain,
                      triaName,
                      type: "EOA",
                    })
                  )
                )

                return tokenDetails
              })
            )
          ).flat()

          // Transform AssetDetail to AssetForTriaName
          const transformedCustomAssets: AssetForTriaName[] =
            customChainDetails.map((customAsset) => ({
              wallet: customAsset.wallet,
              name: customAsset.name,
              symbol: customAsset.symbol,
              logoUrl: customAsset.logoUrl,
              chainName: customAsset.chainName,
              chainLogo: customAsset.chainLogo,
              balanceInTokens: customAsset.balanceInTokens,
              balanceOfTokensInUnits: customAsset.balanceOfTokensInUnits,
              decimals: customAsset.decimals,
              balanceInUSD: customAsset.balanceInUSD,
              quoteRate: customAsset.quoteRate,
              tokenAddress: customAsset.tokenAddress,
              isNativeToken: customAsset.isNativeToken,
              isSpam: customAsset.isSpam,
              percentChangein24hr: customAsset.percentChangein24hr,
              isCustomToken: true,
            }))

          // Combine assets and transformedCustomAssets into a single array
          const combinedAssets = [...assets, ...transformedCustomAssets]

          dispatch(updateUserAssets(combinedAssets))
        } else {
          dispatch(updateUserAssets(assets))
        }

        dispatch(updateUserAssetsLastFetch(new Date().toISOString()))
      } catch (error: any) {
        console.error(error)
      } finally {
        setIsLoading(false)
        dispatch(updateRefreshLoad(false))
      }
    }
  }

  useEffect(() => {
    if (triaName) {
      fetchAssetsForATriaName()

      const intervalId = setInterval(fetchAssetsForATriaName, 10000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [triaName, dispatch, assetsDataExists, userInactive])

  useEffect(() => {
    if (reload) {
      fetchAssetsForATriaName()
      setIsLoading(true)
    }
  }, [reload])

  useEffect(() => {
    if (!refreshLoading && isLoading) {
      console.log("refresh again")
      dispatch(updateRefreshLoad(true))
    }
  }, [refreshLoading, isLoading, dispatch])

  return isLoading
}
