import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../package/ui.common"
import { RootState } from "../package/rx.core"
import {
  updateRefreshLoad,
  updateUserNfts,
  updateUserNftsLastFetch,
} from "../package/rx.core/src/redux/features"
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext"

export function useFetchNfts() {
  const dispatch = useDispatch()
  const { reload } = useReload()
  const { getNfts } = useTriaUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [triaName, setTriaName] = useState<string>()
  const lastFetchTime = useSelector(
    (state: RootState) => state.User.userNftsLastFetch
  )
  const nftsDataExists = useSelector(
    (state: RootState) => state.User.userNfts.length > 0
  )
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  )

  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  )
  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
    console.log("fetch nft called")
  }, [])

  const fetchNfts = async () => {
    const now = new Date()
    if (
      !lastFetchTime ||
      (now.getTime() - new Date(lastFetchTime).getTime() >= 10000 &&
        !userInactive) ||
      reload
    ) {
      try {
        if (!nftsDataExists && !lastFetchTime) {
          setLoading(true)
        }
        if (triaName) {
          const items = await getNfts(undefined, undefined, "", [], triaName)
          dispatch(updateUserNfts(items))
          dispatch(updateUserNftsLastFetch(new Date().toISOString()))
        }
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
        dispatch(updateRefreshLoad(false))
      }
    }
  }

  useEffect(() => {
    fetchNfts()
    const intervalId = setInterval(fetchNfts, 10000)
    return () => clearInterval(intervalId)
  }, [triaName, dispatch, lastFetchTime, userInactive])

  useEffect(() => {
    if (reload) {
      fetchNfts()
      setLoading(true)
    }
  }, [reload])
  useEffect(() => {
    if (!refreshLoading && loading) {
      dispatch(updateRefreshLoad(true))
    }
  }, [refreshLoading])
  return { loading }
}
