import Home from "./pages/home"
import Nft from "./pages/nft"
import TransactionListPage from "./pages/activity"
import TransactionDetailCrypto from "./package/ui.common/src/layouts/Activity/TransactionDetailCrypto"

import { Routes, Route, useParams } from "react-router-dom"
import "./index.css"
import { useEffect, useState } from "react"
import OneNft from "./package/ui.common/src/layouts/Nfts/OneNft"
import { AuthIframe } from "./package/ui.common/src/components"
import { SendCrypto } from "./pages/sendcrypto"
import { BuyCrypto } from "./pages/buycrypto"
import { UserConversations } from "./pages/conversations"
import { AssetDetailHistory } from "./pages/assethistory"
import { SendToken } from "./pages/sendtoken"
import HomeBackgroundVector from "./package/ui.common/src/layouts/UI/HomeBackgroundVector"
import { useHandleParams } from "./hooks"

import { SendNft } from "./pages/sendnft"
import TransactionListPagePending from "./pages/activitypending"
import { Toast } from "./package/ui.common/src/components/Toast"
import {
  RootState,
  closeToast,
  openToast,
  updatePinSetup,
  updateSocialLoginStatus,
} from "./package/rx.core"
import { useDispatch, useSelector } from "react-redux"
import { useDetectInactivity } from "./hooks/useDetectInactivity"
import { Swap } from "./pages/swap"
import { AuthController } from "@tria-sdk/core"
import { LogoutModal } from "./package/ui.common/src/components/Popups/LogoutModal"
import { listenForAccessToken, listenIdToken } from "@tria-sdk/connect"
import AddCustomChain from "./pages/addcustomchain"
import AddCustomToken from "./pages/addCustomToken"
import ApproveCustomChain from "./pages/approvecustomchain"
import CustomChain from "./pages/customchain"
import CustomToken from "./pages/customtoken"
import EditToken from "./pages/edittoken"
import EditChain from "./pages/editchain"

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true)
  const [navigateToHome, setNavigateToHome] = useState<boolean>(false)
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false)
  const param = useParams()
  const AUTH_URL = process.env.REACT_APP_AUTH_URL || ""
  const { isLoading } = useHandleParams(param.params || "", setIsDarkMode)
  const dispatch = useDispatch()
  const { customChains } = useSelector((store: RootState) => store?.userpref)
  useDetectInactivity()
  const inactivity = useSelector(
    (store: RootState) => store?.User?.isUserInactive
  )
  useEffect(() => {
    console.log("call for eventsource")
    const eventSource = new EventSource(
      `${process.env.REACT_APP_GAINS_URL}/events`
    )

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.status === "loggedIn") {
        console.log("call eventsource response", event.data)

        const walletStore = localStorage.getItem("tria.wallet.store.app")
        if (walletStore) {
          const triaName = JSON.parse(walletStore)?.triaName
          if (data.userId === triaName) {
            dispatch(updateSocialLoginStatus(true))
            localStorage.setItem("socialLoggedIn", "true")
          }
        }
      }
    }

    eventSource.onerror = (event) => {
      console.log(event)
    }

    return () => {
      eventSource.close()
    }
  }, [])

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setIsDarkMode(parsedData?.darkMode)
      // setIsDarkMode(false)
      setNavigateToHome(true)
    } else {
      console.log("Can't find triaName;")
    }
  }, [isLoading])

  useEffect(() => {
    const preloader = document.getElementById("preloader")
    if (preloader) {
      // Hide the preloader once the app is loaded
      const theme = localStorage.getItem("theme")
      if (theme && theme === "dark") {
        document.documentElement.classList.add("dark")
      } else {
        document.documentElement.classList.remove("dark")
      }
      preloader.style.display = "none"
    }
  }, [])
  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.log("parent domain", process.env.REACT_APP_AUTH_URL, event)
      if (event?.data?.type === "fetch-user-prefs") {
        console.log("sending user prefs", {
          type: "user-prefs-res",
          customChains,
        })
        ;(event.source as Window).postMessage(
          { type: "user-prefs-res", customChains },
          event.origin
        )
      } else if (event?.data?.type === "accesstoken-call") {
        if (event.origin === process.env.REACT_APP_AUTH_URL) {
          console.log("Received message from parent:", event)

          if (event.source) {
            console.log("token event", event)
            localStorage.setItem("tria.accessToken", event?.data?.token)
            ;(event.source as Window).postMessage("Received", event.origin)
          }
        }
      } else if (event?.data?.type === "disconnect-called") {
        console.log("Disconnect called message received")
        // Call your desired function here
        localStorage.clear()
        window.parent.postMessage({ type: "disconnect-callback" }, "*")
      }
    }

    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  useEffect(() => {
    const initialize = async () => {
      // await listenForAccessToken({ authUrl: AUTH_URL })
      // await listenIdToken({ authUrl: AUTH_URL })
    }

    let cleanupFunction: any
    initialize().then((cleanup) => {
      cleanupFunction = cleanup
    })

    return () => {
      if (cleanupFunction) cleanupFunction()
    }
  }, [])

  return (
    <>
      <AuthIframe src={AUTH_URL} />
      {!isLoading && (
        <div className={`overflow-hidden`}>
          <div
            className={`z-50 bg-primaryColor dark:bg-primaryDarkColor overflow-hidden rounded-2xl select-none`}
          >
            <div className='absolute top-0 moving-div'>
              {" "}
              <HomeBackgroundVector />
            </div>
            {showLogoutModal && (
              <div style={{ zIndex: 1000 }}>
                {process.env.REACT_APP_ENVIRONMENT === "local" ? null : (
                  <LogoutModal setOpenState={setShowLogoutModal} />
                )}
              </div>
            )}
            <div style={{ zIndex: 1000 }}>
              <Toast />
            </div>

            <Routes>
              <Route path='/:params' element={<App />} />
              <Route path='/home' element={<Home />} />
              <Route path='/nfts' element={<Nft />} />
              <Route path='/home/buycrypto' element={<BuyCrypto />} />
              <Route path='/home/sendCrypto' element={<SendCrypto />} />
              <Route
                path='/home/sendCrypto/conversations'
                element={<UserConversations />}
              />
              <Route
                path='/home/sendCrypto/cryptoSendPage'
                element={<SendToken />}
              />
              <Route path='/activity' element={<TransactionListPage />} />
              <Route
                path='/activities/:params'
                element={<TransactionListPagePending />}
              />
              <Route
                path='/activity/TransactionDetailCrypto'
                element={<TransactionDetailCrypto />}
              />
              <Route path='/nfts/oneNft' element={<OneNft />} />
              <Route path='/home/sendCrypto/nftsend' element={<SendNft />} />
              <Route path='/assethistory' element={<AssetDetailHistory />} />
              <Route path='/swap' element={<Swap />} />
              <Route path='/addcustomchain' element={<AddCustomChain />} />
              <Route path='/addcustomtoken' element={<AddCustomToken />} />
              <Route
                path='/approvecustomchain'
                element={<ApproveCustomChain />}
              />
              <Route path='/customchain' element={<CustomChain />} />
              <Route path='/customtoken' element={<CustomToken />} />
              <Route path='/edittoken' element={<EditToken />} />
              <Route path='/editchain' element={<EditChain />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  )
}

export default App
