import { useNavigate } from "react-router-dom"
import {
  AssetSuggestionCard,
  ConversationNav,
  Opentria,
} from "../../package/ui.common"
import { useFetchOnChainConversation } from "../../hooks"
import { ConversationCard } from "../../package/ui.common/src/components/Cards/ConversationCard"
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Conversation,
  RootState,
  updateCurrentRoute,
} from "../../package/rx.core"
import "../../index.css"
import CryptoSend from "../../package/ui.common/src/components/LoadersPages/CryptoSend"
import { NftConversationCard } from "../../package/ui.common/src/components/Cards"

interface GroupedConversations {
  [key: string]: Conversation[]
}

export const UserConversations: React.FC = () => {
  const [triaName, setTriaName] = useState<string>()
  const [groupedConversations, setGroupedConversations] =
    useState<GroupedConversations>({})
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false)
  const { fetchCoversation, isLoading } = useFetchOnChainConversation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectRef = useRef<HTMLDivElement>(null)
  const conversations = useSelector(
    (store: RootState) => store?.Caches?.onChainConversation
  )

  dispatch(updateCurrentRoute(window.location.pathname))

  const userHistory = useSelector((state: RootState) => state.User.userHistory)
  const withTriaName = useSelector(
    (store: RootState) => store.SendToken.tokenState.recipientTriaName
  )

  const withAddress = useSelector(
    (store: RootState) => store.SendToken?.tokenState?.recipientAddress
  )
  const prevPath = useSelector(
    (store: RootState) => store?.Route?.previousRoute
  )
  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp)
    const today = new Date()
    const yesterday = new Date(today.setDate(today.getDate() - 1))

    if (date.toDateString() === new Date().toDateString()) {
      return "Today"
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday"
    } else {
      return date.toDateString()
    }
  }
  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  useEffect(() => {
    if (triaName && withTriaName) {
      fetchCoversation(withTriaName, triaName)
    } else if (triaName && withAddress) {
      fetchCoversation(withAddress, triaName)
    }
  }, [triaName])

  useEffect(() => {
    if (conversations) {
      const groupedByDay = conversations.reduce(
        (acc: GroupedConversations, conversation) => {
          const dateLabel = formatDay(conversation.timestamp)
          if (!acc[dateLabel]) {
            acc[dateLabel] = []
          }
          acc[dateLabel].push(conversation)
          return acc
        },
        {}
      )
      setGroupedConversations(groupedByDay)
    }
  }, [conversations])

  function HandleClick() {
    // console.log(prevPath)
    if (prevPath) navigate(prevPath)
    else navigate("/home/sendcrypto")
  }

  useEffect(() => {
    if (conversations && conversations?.length > 0) {
      var objDiv = document.getElementById("conv")
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight
    }
  }, [conversations])

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowSuggestion(false)
      } else {
        setShowSuggestion(true)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [])

  useEffect(() => {
    if (conversations?.length === 0) setShowSuggestion(false)
  }, [conversations])

  const handleConversationClick = (txHash: string) => {
    const selected = userHistory.find((item) => item.txnHash === txHash)
    if (selected) {
      // setSelectedTransaction(selected)
      navigate("/activity/TransactionDetailCrypto", { state: { selected } })
    }
  }

  return (
    <div>
      <div className='w-[53vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh] h-[100vh] p-[2vh] flex-col justify-between  inline-flex overflow-hidden relative'>
        <div className='h-[13.45vh] flex-col justify-start items-center flex'>
          <ConversationNav onClick={HandleClick} />

          {isLoading && <CryptoSend />}
          {!isLoading && (
            <div
              className={`self-stretch rounded-[2vh] ${
                conversations && conversations?.length > 0
                  ? "justify-start"
                  : "justify-end"
              } items-center flex flex-col overflow-x-hidden overflow-y-auto pt-[2.4vh] h-[77.4vh] min-h-[77.4vh]`}
              style={{ transform: "scale(1,-1)" }}
            >
              {conversations && conversations?.length > 0 ? (
                <div className={`flex flex-col items-end justify-end w-full`}>
                  {Object.entries(groupedConversations)?.map(
                    ([dateLabel, conversationsForDay]) => (
                      <>
                        {conversationsForDay?.map((item) => (
                          <div
                            className={`display flex items-center gap-[0.5vh] my-[1vh] self-stretch cursor-pointer ${
                              item?.tag === "Sent"
                                ? "justify-end"
                                : "justify-start"
                            }`}
                            key={item?.timestamp}
                          >
                            {!item?.nft?.amount && item?.asset?.symbol && (
                              <div
                                className='w-[26.2vh] p-[2.4vh] bg-hoverColor dark:bg-hoverDarkColor rounded-[2vh] justify-between items-start inline-flex'
                                style={{ transform: "scale(1,-1)" }}
                              >
                                <ConversationCard
                                  item={item}
                                  onClick={() =>
                                    handleConversationClick(item?.txnHash || "")
                                  }
                                />
                              </div>
                            )}
                            {item?.nft?.amount && !item?.asset?.symbol && (
                              <div
                                className='w-[26.2vh] bg-hoverColor dark:bg-hoverDarkColor rounded-[16px] justify-between items-start inline-flex'
                                style={{ transform: "scale(1,-1)" }}
                              >
                                <NftConversationCard
                                  item={item}
                                  onClick={() =>
                                    handleConversationClick(item?.txnHash || "")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ))}
                        <div
                          className='w-full flex items-center justify-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-[600] py-[1.2vh]'
                          style={{ transform: "scale(1,-1)" }}
                        >
                          {dateLabel}
                        </div>
                      </>
                    )
                  )}
                </div>
              ) : (
                <div className='self-stretch h-[4.9vh] px-[2.4vh] py-[1.4vh] rounded-[2.5vh] flex-col justify-center items-end gap-[1vh] flex'>
                  <div className='self-stretch justify-start items-start gap-[2vh] inline-flex'>
                    <div className='grow shrink basis-0 flex-col justify-center items-center gap-[0.5vh] inline-flex '>
                      <div className='self-stretch justify-center items-center gap-[1vh] inline-flex'>
                        <div
                          className='text-center text-fontLightColor dark:text-fontLightColorDark text-[2.1vh] leading-tight font-[600] '
                          style={{ transform: "scale(1,-1)" }}
                        >
                          No recent activities
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='absolute bottom-0 left-0 w-[53vh]'>
          <div
            className={`w-full h-full ${showSuggestion ? "pb-[10vh]" : ""}`}
            ref={selectRef}
          >
            <AssetSuggestionCard
              expanded={showSuggestion}
              onClick={() => {
                setShowSuggestion(false)
              }}
            />
          </div>
        </div>
        <div className='self-stretch dark:h-0 dark:w-0 h-[9.2vh] flex-col justify-center items-center dark:hidden gap-[1vh] flex rounded-b-[1vh] absolute w-full bottom-[0vh] nft-footer-bg-gradient '>
          <Opentria />
        </div>
        <div className=' w-0 h-0  dark:h-[9.2vh] flex-col justify-center items-center gap-[1vh] dark:flex  rounded-b-0 absolute dark:w-full bottom-[0vh] nft-footer-bg-gradient-dark'>
          <Opentria />
        </div>
      </div>
    </div>
  )
}
