import React from "react"
import { Nav, Opentria } from "../../package/ui.common"
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton"
import { useNavigate } from "react-router-dom"
import SearchCustomToken from "../../package/ui.common/src/layouts/Search/SearchCustomToken"
import SearchCustomChain from "../../package/ui.common/src/layouts/Search/SearchCustomChain"
import { useDispatch, useSelector } from "react-redux"
import {
  CustomTokenDataType,
  RootState,
  updateCurrentEditToken,
} from "../../package/rx.core"

const CustomToken = () => {
  const navigate = useNavigate()
  const HandleClick = () => {
    navigate("/home")
  }
  const customTokens = useSelector(
    (store: RootState) => store?.userpref?.customTokens
  )
  const dispatch = useDispatch()
  const handleTokenClick = (token: CustomTokenDataType) => {
    dispatch(updateCurrentEditToken(token))
    navigate("/edittoken")
  }
  return (
    <div>
      <div className='flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor '>
        <div className='w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat'>
          <div className='w-full grow shrink basis-0 flex-col justify-start items-center flex'>
            <Nav />
            {/* <Toast /> */}
            <div className='w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex'>
              <div className='self-stretch justify-start items-start gap-[1.6vh] flex flex-col '>
                <div className='h-[2.8vh] justify-start items-center gap-[1vh] flex'>
                  <div className='w-[2vh] h-[2vh] justify-center items-center flex'>
                    <div className='w-[1.67vh] h-[1.67vh] relative'>
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal'>
                    Custom Tokens
                  </div>
                </div>
                <SearchCustomToken />
                {/* <p className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-tight font-semibold tracking-tight ml-[1.5vh]'>
                  Add new network manually
                </p> */}
              </div>
            </div>
            <div className='w-full mt-[10vh] px-[2vh]'>
              {customTokens ? (
                customTokens?.map((token) => (
                  <div
                    className='flex bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor hover:dark:bg-hoverDarkColor w-full py-[2vh] gap-[2vh] rounded-[2vh] px-[2vh] items-center'
                    onClick={() => handleTokenClick(token)}
                  >
                    <div className='rounded-[4vh] flex items-center justify-center uppercase font-semibold text-white bg-purple-500 w-[4vh] h-[4vh]'>
                      <p>{token?.tokenSymbol?.slice(0, 1)}</p>
                    </div>

                    <p className='text-[1.87vh] font-semibold text-fontPrimaryColor dark:text-fontPrimaryDarkColor '>
                      {token?.tokenSymbol}
                    </p>
                  </div>
                ))
              ) : (
                <p>NO TOKENS ADDED</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='absolute bottom-[2vh] left-0 right-0 h-[11vh] w-full flex items-start justify-center py-[2vh]'>
        {/* <Footer currentActive='/home' /> */}
        <button
          className='bg-primaryDarkColor dark:bg-primaryColor py-[1.4vh] px-[2.4vh] w-[50vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%] '
          onClick={() => {
            navigate("/addcustomtoken")
          }}
        >
          ADD NEW TOKEN
        </button>
      </div>
      <div className='absolute bottom-[0vh] invisible dark:visible  flex items-center justify-center left-0 right-0 w-full '>
        {/* <Footer currentActive='/home' /> */}
        <Opentria />
      </div>
    </div>
  )
}

export default CustomToken
