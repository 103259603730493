import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { StaticItemsState } from "./constants.model"
import { NetworkItem } from "../../../types"

const INITIAL_STATE: StaticItemsState = {
  NetworkItems: [],
  AANetworks: [],
}

export const staticItemsSlice = createSlice({
  name: "Constants",
  initialState: INITIAL_STATE,
  reducers: {
    updateNetworks: (state, action: PayloadAction<NetworkItem[]>) => {
      state.NetworkItems = action.payload
    },
    updateAANetworks: (state, action: PayloadAction<NetworkItem[]>) => {
      state.AANetworks = action.payload
    },
  },
})

export const { updateNetworks, updateAANetworks } = staticItemsSlice.actions
