import { useEffect, useState } from "react"
import {
  HistoryItem,
  updateApiTimer,
  updateAssetHistoryCache,
} from "../package/rx.core"
import { useSelector, useDispatch } from "react-redux"
import { store, RootState } from "../package/rx.core"
import { useTriaUser } from "../package/ui.common"

export const useFetchAssetHistory = () => {
  const dispatch = useDispatch()
  const { getAssetHistory } = useTriaUser()
  const { chainName } = useSelector(
    (store: RootState) => store.Asset.fetchParam
  )
  const [assetHistory, setAssetHistory] = useState<HistoryItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const tokenAddress = useSelector(
    (store: RootState) => store.Asset.fetchParam.tokenAddress
  )
  const [triaName, setTriaName] = useState<string>()

  const { prevFetch, time } = useSelector(
    (store: RootState) => store.ApiLogs?.apiTimer?.getAssetHistory
  )

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchAssetHistory = async (chainName: string, triaName: string) => {
    if (triaName) {
      if (
        prevFetch.chainName == chainName &&
        prevFetch.tokenAddress == tokenAddress &&
        Date.now() - time < 20000
      ) {
      } else {
        try {
          setIsLoading(true)
          const response = await getAssetHistory(
            chainName,
            tokenAddress,
            triaName
          )
          setAssetHistory(response)
          dispatch(
            updateApiTimer({
              apiEndpoint: "getAssetHistory",
              time: Date.now(),
              prevFetch: { chainName, tokenAddress },
            })
          )
          dispatch(updateAssetHistoryCache(response))
        } catch (error) {
          console.error(error)
          const { message } = error as Error
          return Promise.reject(String(message || error))
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    fetchAssetHistory(chainName || "POLYGON", triaName || "")
  }, [triaName])
  return isLoading
}
