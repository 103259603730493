import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import React from "react"
/* Types */
import type { PropsWithChildren } from "react"

/* Data Things */
import { ChainData, UserController, UserPrefResponse } from "@tria-sdk/core"
import {
  GetAllNetworkResponse,
  GetAssetsForATriaNameResponse,
  HistoryFilterOption,
  HistoryItem,
  HistoryItemResult,
  NetworkItem,
  Conversation,
  GetOnChainConversationResponse,
  WithSuccess,
  AssetForTriaName,
  GetAssetHistoryResponse,
  GetAssetDetailsResponse,
  NftsItem,
  NftsItemDetails,
  GetPopularTokenItem,
  RecentUser,
  SearchUserResult,
  SearchBuyTokenResponse,
  AvatarItem,
  CustomChainDataType,
  CustomTokenDataType,
} from "../../../../rx.core/src"
import { GetTotalBalanceResponse } from "@tria-sdk/core"

interface TriaUserContext {
  getAllHistory(
    filter: HistoryFilterOption,
    filterChainNames?: string[],
    triaName?: string
  ): Promise<HistoryItem[]>
  getAllNetworks(type?: "EOA" | "AA"): Promise<NetworkItem[]>
  getOnChainConversation(
    withTriaName: string,
    triaName: string
  ): Promise<Conversation[]>
  getAssetsForATriaName(
    triaName: string,
    filterChainNames?: string[],
    sort?: "amountAsc" | "amountDesc"
  ): Promise<AssetForTriaName[]>
  getOnRampUrl(address: string, tokenSymbol: string): Promise<string>
  getTotalBalance(triaName: string): Promise<GetTotalBalanceResponse>
  getAssetDetails({
    chainName,
    tokenAddress,
    chain,
    type,
    triaName,
  }: {
    chainName?: string
    tokenAddress?: string
    chain?: CustomChainDataType
    type?: "EOA" | "AA"
    triaName?: string
  }): Promise<Required<GetAssetDetailsResponse>["data"]>
  getAssetHistory(
    chainName: string,
    tokenAddress?: string,
    triaName?: string
  ): Promise<HistoryItem[]>
  getNfts(
    sort?: "A-Z" | "Z-A" | "Ascending buy date" | "Descending buy date",
    filter?: "all" | "favorites",
    filterByCollectionId?: string,
    filterChainNames?: string[],
    triaName?: string
  ): Promise<NftsItem[]>
  getNFTDetails(
    chainName: string,
    tokenAddress: string,
    tokenId: string,
    triaName?: string
  ): Promise<NftsItemDetails>
  getPopularToken(): Promise<GetPopularTokenItem>
  getRecentUsers(
    triaName: string
  ): Promise<{ success: boolean; data: RecentUser[] }>
  searchUser(term: string): Promise<SearchUserResult>
  getAddressByChainName(triaName: string, chainName: string): Promise<string>
  searchBuyToken(
    tokenName: string,
    currentChain: string
  ): Promise<SearchBuyTokenResponse>
  updateAvatar(accessToken?: string): Promise<AvatarItem>
  getAvatar(accessToken?: string): Promise<AvatarItem>
  getUserByAddress(address: string, chainName: string): Promise<string>
  addCustomChain(
    accessToken: string,
    customChain: CustomChainDataType
  ): Promise<{ success: boolean; message: string }>
  editCustomChain(
    accessToken: string,
    customChain: CustomChainDataType
  ): Promise<{ success: boolean; message: string }>
  addCustomToken(
    accessToken: string,
    customToken: CustomTokenDataType
  ): Promise<{ success: boolean; message: string }>
  editCustomToken(
    accessToken: string,
    customToken: CustomTokenDataType
  ): Promise<{ success: boolean; message: string }>
  deleteCustomToken(
    accessToken: string,
    customToken: { chainId: number; tokenAddress: string }
  ): Promise<{ success: boolean; message: string }>
  deleteCustomChain(
    accessToken: string,
    customChain: { chainId: number }
  ): Promise<{ success: boolean; message: string }>
  getUserPrefs(): Promise<UserPrefResponse>
}

const initialValue = {
  searchUser: () => Promise.reject(""),
  getAllHistory: () => Promise.reject(null),
  getAllNetworks: () => Promise.reject(null),
  getOnChainConversation: () => Promise.reject(null),
  getAssetsForATriaName: () => Promise.reject(null),
  getOnRampUrl: () => Promise.reject(null),
  getTotalBalance: () => Promise.reject(null),
  getAssetDetails: () => Promise.reject(null),
  getAssetHistory: () => Promise.reject(null),
  getNfts: () => Promise.reject(null),
  getNFTDetails: () => Promise.reject(null),
  getPopularToken: () => Promise.reject(null),
  getRecentUsers: () => Promise.reject(null),
  getAddressByChainName: () => Promise.reject(null),
  searchBuyToken: () => Promise.reject(null),
  updateAvatar: () => Promise.reject(null),
  getAvatar: () => Promise.reject(null),
  getUserByAddress: () => Promise.reject(null),
  addCustomChain: () => Promise.reject(null),
  editCustomChain: () => Promise.reject(null),
  addCustomToken: () => Promise.reject(null),
  editCustomToken: () => Promise.reject(null),
  getUserPrefs: () => Promise.reject(null),
  deleteCustomChain: () => Promise.reject(null),
  deleteCustomToken: () => Promise.reject(null),
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const TriaUserContext = createContext<TriaUserContext>(initialValue)

// This hook can be used to access the user info.
export const useTriaUser = () => useContext(TriaUserContext)
// const triaName = useSelector((store:RootState) => store.AppState.AppCurrentState.triaName)

const userController = new UserController(
  process.env.REACT_APP_SDK_BASE_URL ?? ""
)
export function UserProvider(props: PropsWithChildren) {
  const [unauthHandlerId, setUnauthHandlerId] = useState<number | undefined>(
    undefined
  )

  const getNfts = useCallback(
    async (
      sort?: "A-Z" | "Z-A" | "Ascending buy date" | "Descending buy date",
      filter?: "all" | "favorites",
      filterByCollectionId?: string,
      filterChainNames?: string[],
      triaName?: string
    ): Promise<NftsItem[]> => {
      try {
        const response = await userController.getNFTs(
          sort,
          filter,
          filterByCollectionId,
          filterChainNames,
          triaName
        )
        if (!response.success) {
          throw new Error((response as unknown as Error).message)
        }
        return response.data
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getNFTDetails = useCallback(
    async (
      chainName: string,
      tokenAddress: string,
      tokenId: string,
      triaName?: string
    ): Promise<NftsItemDetails> => {
      try {
        const response = await userController.getNFTDetails(
          chainName,
          tokenAddress,
          tokenId,
          triaName
        )
        if (!response.success) {
          throw new Error((response as unknown as Error).message)
        }

        return response.data
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const searchUser = useCallback(async (term: string) => {
    try {
      const response = (await userController.searchUser(
        term
      )) as WithSuccess<SearchUserResult>

      if (!response.success) {
        throw new Error((response as unknown as Error).message)
      }

      return response
    } catch (error) {
      console.error(error)
      const { message } = error as Error
      return Promise.reject(String(message || error))
    }
  }, [])

  const getAllHistory = useCallback(
    async (
      filter: HistoryFilterOption,
      filterChainNames?: string[],
      triaName?: string
    ): Promise<HistoryItem[]> => {
      try {
        const response = (await userController.getAllHistory(
          filter,
          filterChainNames,
          triaName
        )) as WithSuccess<HistoryItemResult>

        if (!response.success) {
          throw new Error((response as unknown as Error).message)
        }

        return response.history
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getAllNetworks = useCallback(
    async (type?: "EOA" | "AA"): Promise<NetworkItem[]> => {
      try {
        const response = (await userController
          .getAllNetworks
          // type
          ()) as GetAllNetworkResponse
        if (!response.success) {
          throw new Error((response as unknown as Error).message)
        }

        return response.networks
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getOnChainConversation = useCallback(
    async (withTriaName: string, triaName: string): Promise<Conversation[]> => {
      if (triaName) {
        try {
          const response = (await userController.getOnChainConversation(
            withTriaName,
            triaName
          )) as WithSuccess<GetOnChainConversationResponse>
          if (!response.success) {
            throw new Error((response as unknown as Error).message)
          }
          return response.conversation
        } catch (error) {
          console.error(error)
          const { message } = error as Error
          return Promise.reject(String(message || error))
        }
      } else {
        return []
      }
    },
    []
  )

  const getAssetsForATriaName = useCallback(
    async (
      triaName: string,
      filterChainNames?: string[],
      sort?: "amountAsc" | "amountDesc"
    ) => {
      try {
        const response = (await (userController.getAssetsForATriaName as any)(
          triaName,
          filterChainNames,
          sort ? sort : "amountDesc"
        )) as WithSuccess<GetAssetsForATriaNameResponse>

        if (!response.success) {
          throw new Error((response as unknown as Error).message)
        }

        return response.assets
      } catch (error) {
        // console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getOnRampUrl = useCallback(
    async (address: string, tokenSymbol: string): Promise<string> => {
      try {
        const response = await userController.getRampnalysisUrl(
          address,
          tokenSymbol
        )
        if (!response.success || response.url === null) {
          throw new Error((response as unknown as Error).message)
        }

        return response.url
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getTotalBalance = useCallback(
    async (_triaName: string): Promise<GetTotalBalanceResponse> => {
      try {
        const response = (await userController.getTotalBalance(
          _triaName
        )) as GetTotalBalanceResponse

        return response
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getAssetDetails = useCallback(
    async ({
      chainName,
      tokenAddress,
      chain,
      type,
      triaName,
    }: {
      chainName?: string
      tokenAddress?: string
      chain?: CustomChainDataType
      type?: "EOA" | "AA"
      triaName?: string
    }) => {
      try {
        const response = (await userController.getAssetDetails({
          chain: chain ? { customChain: chain } : { chainName },
          tokenAddress,
          type,
          triaName,
        })) as GetAssetDetailsResponse

        if (!response.success || !response.data) {
          throw new Error(response.message)
        }

        return response.data
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getAssetHistory = useCallback(
    async (chainName: string, tokenAddress?: string, triaName?: string) => {
      try {
        const response = (await userController.getAssetHistory(
          chainName,
          tokenAddress,
          triaName
        )) as GetAssetHistoryResponse

        if (!response.success || !response.data) {
          throw new Error(response.message)
        }
        return response.data
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const getPopularToken = useCallback(async () => {
    {
      try {
        const response =
          (await userController.getPopularToken()) as GetPopularTokenItem

        return response
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    }
  }, [])

  const getRecentUsers = useCallback(async (triaName: string) => {
    try {
      const response = (await userController.getRecentUsersForUser(
        triaName
      )) as { success: boolean; data: RecentUser[] }

      if (!response.success || !response.data) {
        throw new Error("Error in getting Recent User!")
      }
      return response
    } catch (error) {
      console.error(error)
      const { message } = error as Error
      return Promise.reject(String(message || error))
    }
  }, [])

  const getAddressByChainName = useCallback(
    async (triaName: string, chainName: string) => {
      try {
        const response = (await userController.getAddressByChainName(
          triaName,
          chainName
        )) as { address: string }
        // if(response.address.length == 0){
        //   throw new Error("No address found!");
        // }
        return response.address
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const searchBuyToken = useCallback(
    async (tokenName: string, currentChain: string) => {
      try {
        const response = (await userController.searchBuyToken(
          tokenName,
          currentChain
        )) as SearchBuyTokenResponse
        return response
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )

  const updateAvatar = useCallback(async (accessToken?: string) => {
    try {
      const response = (await userController.updateAvatar(
        accessToken || ""
      )) as AvatarItem

      return response
    } catch (error) {
      console.error(error)
      const { message } = error as Error
      return Promise.reject(String(message || error))
    }
  }, [])

  const getAvatar = useCallback(async (accessToken?: string) => {
    try {
      const response = (await userController.getAvatar(
        accessToken || ""
      )) as AvatarItem
      return response
    } catch (error) {
      console.error(error)
      const { message } = error as Error
      return Promise.reject(String(message || error))
    }
  }, [])

  const getUserByAddress = useCallback(
    async (address: string, chainName: string) => {
      try {
        const response = (await userController.getUserByAddress(
          address,
          chainName
        )) as { success: boolean; triaName: string }
        if (!response.success) {
          throw new Error("Failed to fetch TriaName!")
        }
        return response?.triaName
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      }
    },
    []
  )
  const addCustomChain = useCallback(
    async (accessToken: string, customChain: CustomChainDataType) => {
      try {
        const response = (await userController.addCustomChain({
          accessToken,
          customChain,
        })) as { success: boolean; message: string }
        return response
        console.log("add custom chain response", response)
      } catch (err) {
        throw err
      }
    },
    []
  )
  const editCustomChain = useCallback(
    async (accessToken: string, customChain: CustomChainDataType) => {
      try {
        const response = (await userController.updateCustomChain({
          accessToken,
          customChain,
        })) as { success: boolean; message: string }
        return response
        console.log("add custom chain response", response)
      } catch (err) {
        throw err
      }
    },
    []
  )
  const addCustomToken = useCallback(
    async (accessToken: string, customToken: CustomTokenDataType) => {
      try {
        const response = (await userController.addCustomToken({
          accessToken,
          customToken,
        })) as { success: boolean; message: string }
        return response
      } catch (err) {
        throw err
      }
    },
    []
  )
  const editCustomToken = useCallback(
    async (accessToken: string, customToken: CustomTokenDataType) => {
      try {
        const response = (await userController.updateCustomToken({
          accessToken,
          customToken,
        })) as { success: boolean; message: string }
        return response
      } catch (err) {
        throw err
      }
    },
    []
  )
  const deleteCustomToken = useCallback(
    async (
      accessToken: string,
      customToken: { chainId: number; tokenAddress: string }
    ) => {
      try {
        const response = (await userController.deleteCustomToken({
          accessToken,
          customToken,
        })) as { success: boolean; message: string }
        return response
      } catch (err) {
        throw err
      }
    },
    []
  )
  const deleteCustomChain = useCallback(
    async (accessToken: string, customChain: { chainId: number }) => {
      try {
        const response = (await userController.deleteCustomChain({
          accessToken,
          customChain: { chainId: customChain?.chainId?.toFixed(0) },
        })) as { success: boolean; message: string }
        return response
      } catch (err) {
        throw err
      }
    },
    []
  )
  const getUserPrefs = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("tria.accessToken")
      if (accessToken) {
        const response = (await userController.getUserPrefs(
          accessToken
        )) as UserPrefResponse
        return response
      } else {
        throw "AccessToken not found please login again!!"
      }
      // console.log("add custom chain response", response)
    } catch (err) {
      throw err
    }
  }, [])

  const value = useMemo(
    () => ({
      searchUser,
      getAllHistory,
      getAllNetworks,
      getOnChainConversation,
      getAssetsForATriaName,
      getOnRampUrl,
      getTotalBalance,
      getAssetHistory,
      getAssetDetails,
      getNfts,
      getNFTDetails,
      getPopularToken,
      getRecentUsers,
      getAddressByChainName,
      searchBuyToken,
      updateAvatar,
      getAvatar,
      getUserByAddress,
      addCustomChain,
      addCustomToken,
      editCustomChain,
      editCustomToken,
      deleteCustomChain,
      deleteCustomToken,
      getUserPrefs,
    }),
    [
      searchUser,
      getAllHistory,
      getAllNetworks,
      getOnChainConversation,
      getAssetsForATriaName,
      getOnRampUrl,
      getTotalBalance,
      getAssetHistory,
      getAssetDetails,
      getNfts,
      getNFTDetails,
      getPopularToken,
      getRecentUsers,
      getAddressByChainName,
      searchBuyToken,
      updateAvatar,
      getAvatar,
      getUserByAddress,
      addCustomChain,
      addCustomToken,
      editCustomChain,
      editCustomToken,
      deleteCustomChain,
      deleteCustomToken,
      getUserPrefs,
    ]
  )

  return (
    <TriaUserContext.Provider value={value}>
      {props.children}
    </TriaUserContext.Provider>
  )
}
