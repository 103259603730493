import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  RootState,
  updateUserAddressOnChain,
  updateUserChain,
} from "../package/rx.core"
import { useNavigate } from "react-router-dom"

interface CurrentState {
  triaName?: string
  userAddress?: string
  //  appName?: string,
  logo?: string
  darkMode?: boolean
  appDomain?: string
  defaultChain?: string
  //  allowedChains?: string[],
  accessToken?: string
  //  loginType?: string,
  //  socialName?: string,
  //  userId?: string
}

const paramInitialState: CurrentState = {
  triaName: "",
  userAddress: "",
  //  appName: "",
  logo: "",
  darkMode: false,
  appDomain: "",
  defaultChain: "POLYGON",
  //  allowedChains: [],
  accessToken: "",
  //  loginType: "",
  //  socialName: "",
  //  userId: ""
}

export const useHandleParams = (
  values: string,
  setIsDarkMode: Dispatch<SetStateAction<boolean>>
) => {
  const [params, setParams] = useState<CurrentState>(paramInitialState)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean | null>(null)
  const dispatch = useDispatch()
  const route = useSelector((store: RootState) => store?.Route?.currentRoute)
  const navigate = useNavigate()
  useEffect(() => {
    const encodedParams = values
    if (encodedParams) {
      setIsLoading(true)
      const jsonString = atob(encodedParams)
      let jsonData
      if (jsonString) {
        jsonData = JSON.parse(jsonString)
      }

      setParams(jsonData)
    }
  }, [values])

  useEffect(() => {
    if (params.triaName) {
      const appState = JSON.stringify(params)
      localStorage.setItem(`tria.wallet.store.app`, appState)

      dispatch(updateUserChain(params?.defaultChain || "FUSE"))
      dispatch(
        updateUserAddressOnChain({
          triaName: params?.triaName,
          address: params?.userAddress || "",
          type: "AA",
          chainName: params?.defaultChain || "",
        })
      )
      console.log("dark mode setting", params?.darkMode)
      if (params?.darkMode !== null) {
        console.log("changing theme", darkMode)

        if (params?.darkMode) {
          document.documentElement.classList.add("dark")
          localStorage.setItem("theme", "dark")
        } else {
          document.documentElement.classList.remove("dark")
          localStorage.setItem("theme", "light")
        }
      }
      localStorage.setItem("tria.wallet.params", values)
      navigate(route)
      setIsLoading(false)
    }
  }, [params])
  useEffect(() => {
    console.log("useeffect called", darkMode)
  }, [darkMode])

  return { isLoading }
}
