import TransactionListHistory from "../../package/ui.common/src/layouts/Activity/TransactionListHistory"
import TransactionListUsers from "../../package/ui.common/src/layouts/Activity/TransactionListUsers"
import TransactionTabs from "../../package/ui.common/src/layouts/Activity/TransactionTabs"
import { Footer, Header, Nav, UserContainer } from "../../package/ui.common"
import "../../index.css"
import { useEffect, useState } from "react"
import {
  useToast,
  HistoryItem,
  RootState,
  updateCurrentRoute,
} from "../../package/rx.core"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useFetchHistory } from "../../hooks/useFetchHistory"
import { useDispatch, useSelector } from "react-redux"
import { PendingItem } from "../../package/ui.common/src/components/Activity/PendingItem"
import { ChainController } from "@tria-sdk/connect"

function TransactionListPagePending() {
  const [activeTab, setActiveTab] = useState("history")
  const [selectedTransaction, setSelectedTransaction] = useState<
    HistoryItem | undefined
  >(undefined)
  const [data, setData] = useState<any>()
  const dispatch = useDispatch()
  const params = useParams()
  dispatch(updateCurrentRoute(window.location.pathname))

  useEffect(() => {
    if (params) {
      console.log(
        "pending params",
        JSON.parse(decodeURIComponent(params?.params || ""))
      )
      setData(JSON.parse(decodeURIComponent(params?.params || "")))
    }
  }, [params])

  const waitFunctionForAsset = async (data: any) => {
    const chain = new ChainController(data?.params?.chainName)
    // ######### for fuse
    // explorerBaseUrl[chainName]/op/opHash

    await chain.waitForTransaction({
      txnHash: data?.txnId,
      confirmations: 2,
      // userOpHash: data?.params?.opHash,
    })
    navigate("/activity")
  }

  useEffect(() => {
    if (data) {
      waitFunctionForAsset(data)
    }
  }, [data])

  const userHistory = useSelector((state: RootState) => state.User.userHistory)
  const navigate = useNavigate()
  const { setToastData, openToastMessage } = useToast()
  const { t } = useTranslation()
  const { loading } = useFetchHistory()
  const historyItems = useSelector(
    (store: RootState) => store.User?.userHistory
  )

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  const handleHistoryItemClick = (txHash: string) => {
    const selected = userHistory.find((item) => item.txnHash === txHash)
    if (selected) {
      setSelectedTransaction(selected)
      navigate("/activity/TransactionDetailCrypto", { state: { selected } })
    }
  }
  const recentUsers = useSelector(
    (store: RootState) => store.User.userRecentUsers
  )
  return (
    <>
      <div className='w-[53vh] h-[100vh] px-[1.4vh] flex items-center justify-center relative font-montserrat'>
        <div className='w-[100%] h-[98%] py-[2vh] flex-col  items-start gap-[1.4vh] inline-flex rounded-[1.4vh]'>
          <Nav />
          <Header />

          <TransactionTabs
            handleTabClick={handleTabClick}
            activeTab={activeTab}
          />
          {activeTab === "history" && (
            <>
              <div className='w-full flex flex-col py-[1.2vh] justify-start items-center scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-stone-100 '>
                <div className='pb-[1.4vh] grid grid-cols-2 gap-[1vh]'>
                  {recentUsers?.slice(0, 4)?.map((item) => (
                    <>
                      <UserContainer recentUser={item} />
                    </>
                  ))}
                </div>
                <PendingItem data={data} />
                <TransactionListHistory
                  loading={loading}
                  items={historyItems}
                  onClick={handleHistoryItemClick}
                />
              </div>
            </>
          )}
          {activeTab === "users" && <TransactionListUsers />}
          {/* {activeTab === 'recent' && <TransactionListRecent />} */}
          <div className='absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]'>
            <Footer />
          </div>
          <div className='absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionListPagePending
