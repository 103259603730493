import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState, CurrentState } from "./appstate.model"

const initialState: AppState = {
  AppCurrentState: {
    triaName: "",
    reload: false,
    appLogo: "",
    darkMode: true,
    appDomain: "",
    defaultChain: "",
    accessToken: "",
    refreshLoading: false,
    showTestNets: false,
    socialLogin: false,
  },
}

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    updateAppState: (state, action: PayloadAction<CurrentState>) => {
      state.AppCurrentState = action.payload
    },
    updateAppDomain: (state, action: PayloadAction) => {
      state.AppCurrentState.appDomain = ""
    },
    updateReload: (state, action: PayloadAction<boolean>) => {
      state.AppCurrentState.reload = action.payload
    },
    updateRefreshLoad: (state, action: PayloadAction<boolean>) => {
      state.AppCurrentState.refreshLoading = action.payload
    },
    updateShowTestNets: (state, action: PayloadAction<boolean>) => {
      state.AppCurrentState.showTestNets = action.payload
    },
    updateSocialLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.AppCurrentState.socialLogin = action.payload
    },
  },
})

export const {
  updateAppState,
  updateAppDomain,
  updateReload,
  updateRefreshLoad,
  updateShowTestNets,
  updateSocialLoginStatus,
} = appSlice.actions
