import { AxiosError } from "axios"
import { useTriaUser } from "../package/ui.common"
import { useDispatch, useSelector } from "react-redux"
import {
  RootState,
  updateCustomChains,
  updateCustomTokens,
} from "../package/rx.core"
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext"
import { useEffect } from "react"

const useFetchUserPrefs = async () => {
  const { getUserPrefs } = useTriaUser()
  const dispatch = useDispatch()
  const { reload } = useReload()
  const userPrefs = useSelector((store: RootState) => store?.userpref)
  const fetchUserPrefs = async () => {
    try {
      const response = await getUserPrefs()
      console.log("get user prefs response", response)
      dispatch(updateCustomChains(response?.customChains))
      dispatch(updateCustomTokens(response?.customTokens))
    } catch (err) {
      const error = err as AxiosError
      // throw error?.message
      console.error(error)
    }
  }
  useEffect(() => {
    console.log("calling user prefs", userPrefs, reload)
    if (reload) {
      fetchUserPrefs()
    }
  }, [reload])
}

export default useFetchUserPrefs
