import { useNavigate } from "react-router-dom"
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton"
import { useFetchAssetHistory } from "../../hooks/useFetchAssetHistory"
import { useDispatch, useSelector } from "react-redux"
import {
  store,
  RootState,
  updateCurrentRoute,
  formatNumberDisplay,
  updateTokenInfo,
  updatePreviousRoute,
  HistoryItem,
  handleBalance,
  NetworkItem,
} from "../../package/rx.core"
import { WalletIcon } from "../../package/ui.common/src/components/Buttons/WalletIcon"
import { SendIcon } from "../../package/ui.common/src/components/Buttons/SendIcon"

import { AssetHistoryContainer } from "../../package/ui.common/src/components/Containers/Asset/AssetHistoryContainer"

import { useEffect, useRef, useState } from "react"
import { useFetchAssetDetails } from "../../hooks/useFetchAssetDetails"
import { AssetHistoryLoader } from "../../package/ui.common/src/components/LoadersPages"
import { Opentria } from "../../package/ui.common"
import { QrButton } from "../../package/ui.common/src/components/Buttons/QrButton"
import AddressDropdown from "../../package/ui.common/src/components/Dropdowns/AddressDropdown"
import QR from "../../package/ui.common/src/components/Popups/QR"

interface GroupedItems {
  [date: string]: HistoryItem[]
}

export const AssetDetailHistory: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const qrRef = useRef<HTMLDivElement>(null)
  const qrRef2 = useRef<HTMLDivElement>(null)

  dispatch(updateCurrentRoute(window.location.pathname))

  const isLoading2 = useFetchAssetDetails()
  const isLoading = useFetchAssetHistory()

  const [currentAddress, setCurrentAddress] = useState<string>("")
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({})
  const [showHistory, setShowHistory] = useState<"All" | "EOA" | "AA">("All")
  const [assetHistory, setAssetHistory] = useState<HistoryItem[]>()
  const [eoaAddress, setEoaAddress] = useState<string>("")
  const [aaAddress, setaaAddress] = useState<string>("")
  const [showQr, setShowQr] = useState(false)
  const [qrAddress, setQrAddress] = useState("")
  const [qrChain, setQrChain] = useState<NetworkItem>()
  const [qrNetworkItems, setQrNetworkItems] = useState<NetworkItem[]>()
  const [qrLogo, setQrLogo] = useState("")

  const HandleClick = () => {
    navigate("/home")
  }

  const totalAssetHistory = useSelector(
    (store: RootState) => store.Caches?.assetHistory
  )
  const eoavalueInUsd = useSelector(
    (store: RootState) => store.Asset?.assetValue?.eoavalueInUsd
  )
  const aaValueInUsd = useSelector(
    (store: RootState) => store.Asset?.assetValue?.aavalueInUsd
  )
  const eoaAssetBalance = useSelector(
    (store: RootState) => store.Asset?.assetValue?.eoaAssetBalance
  )
  const aaAssetBalance = useSelector(
    (store: RootState) => store.Asset?.assetValue?.aaAssetBalance
  )
  const assetDetail = useSelector(
    (store: RootState) => store.Caches?.assetDetail
  )

  const totalValue = (Array.isArray(assetHistory) ? assetHistory : [])?.reduce(
    (accumulator, asset) => {
      if (asset && asset?.desc && asset?.desc?.value) {
        return accumulator + parseFloat(asset?.desc?.value)
      }
      return accumulator
    },
    0
  )
  useEffect(() => {
    const arr: HistoryItem[] = []
    if (showHistory === "All") {
      setAssetHistory(totalAssetHistory)
    } else if (showHistory === "AA") {
      Array.isArray(totalAssetHistory) &&
        totalAssetHistory?.map((historyItem: HistoryItem) => {
          if (historyItem?.wallet?.type === "AA") {
            arr?.push(historyItem)
          }
        })

      setAssetHistory(arr)
    } else {
      Array.isArray(totalAssetHistory) &&
        totalAssetHistory?.map((historyItem: HistoryItem) => {
          if (historyItem?.wallet?.type === "EOA") {
            arr?.push(historyItem)
          }
        })
      setAssetHistory(arr)
    }
  }, [showHistory, totalAssetHistory])
  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON?.parse(storedData)
      setEoaAddress(parsedData?.addresses?.evm?.address)
      setaaAddress(parsedData?.addresses?.aa?.address)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const handleBuyClick = () => {
    if (typeof window === "undefined") {
      return
    }
    if (currentAddress && assetHistory) {
      console.log(`${assetDetail?.symbol}-${assetDetail?.chainName}`)
      try {
        const url = `https://global.transak.com/?apiKey=83565d1e-05b6-415a-940c-b02baedd55c8&walletAddress=${currentAddress}&cryptoCurrencyCode=${assetDetail?.symbol}&network=${assetDetail?.chainName}`
        window.open(url, "_blank")?.focus()
      } catch (error: any) {
        console.error(error)
      }
    }
  }

  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp)
    const today = new Date()
    const yesterday = new Date(today?.setDate(today?.getDate() - 1))

    if (date?.toDateString() === new Date()?.toDateString()) {
      return "Today"
    } else if (date?.toDateString() === yesterday?.toDateString()) {
      return "Yesterday"
    } else {
      return date?.toDateString()
    }
  }

  useEffect(() => {
    const groups: GroupedItems = {}
    if (assetHistory && assetHistory?.length > 0) {
      Array.isArray(assetHistory) &&
        assetHistory?.forEach((item) => {
          const date = formatDay(item?.timestamp) // Convert UNIX timestamp to a date string

          if (!groups[date]) {
            groups[date] = []
          }
          groups[date]?.push(item)
        })
    }

    setGroupedItems(groups)
  }, [assetHistory])

  const handleSendClick = () => {
    dispatch(
      updateTokenInfo({
        qouteRate: assetDetail?.quoteRate,
        senderBalance:
          showHistory === "All"
            ? aaAssetBalance
              ? aaAssetBalance
              : eoaAssetBalance
            : showHistory === "EOA"
            ? eoaAssetBalance
            : aaAssetBalance,
        tokenAddress: assetDetail?.tokenAddress,
        tokenLogo: assetDetail?.logoUrl,
        chainLogo: assetDetail?.chainLogo,
        chainName: assetDetail?.chainName,
        tokenName: assetDetail?.symbol,
        senderAddress: currentAddress,
        type:
          showHistory === "All"
            ? aaAssetBalance
              ? "AA"
              : "EOA"
            : showHistory === "EOA"
            ? "EOA"
            : "AA",
        isNativeToken: assetDetail?.isNativeToken,
        isCustomToken: assetDetail?.isCustomToken,
      })
    )
    console.log(currentAddress)
    dispatch(updatePreviousRoute("/assetHistory"))
    navigate("/home/sendCrypto")
  }

  useEffect(() => {
    {
      showHistory === "All"
        ? aaAssetBalance > 0
          ? setCurrentAddress(aaAddress)
          : setCurrentAddress(eoaAddress)
        : showHistory === "AA"
        ? setCurrentAddress(aaAddress)
        : setCurrentAddress(eoaAddress)
    }
  }, [aaAssetBalance, eoaAssetBalance, aaAddress, eoaAddress])
  const handleQrClick = (
    address: string,
    qrChain?: NetworkItem,
    qrItems?: NetworkItem[],
    qrLogo?: string
  ) => {
    console.log("function called")
    setShowQr(!showQr)
    setQrAddress(address)
    setQrChain(qrChain)
    setQrNetworkItems(qrItems)
    if (qrLogo) setQrLogo(qrLogo)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        qrRef.current &&
        !qrRef.current.contains(event.target as Node) &&
        !qrRef2.current?.contains(event.target as Node)
      ) {
        setShowQr(!showQr)
      }
    }

    if (showQr) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showQr])
  return (
    <>
      {(isLoading2 || isLoading) && <AssetHistoryLoader />}
      {!(isLoading2 || isLoading) && (
        <div className='w-[53vh] relative overflow-hidden h-[100vh] p-[2vh] flex-col justify-center bg-primaryColor dark:bg-primaryDarkColor items-center gap-[1.4vh] inline-flex rounded-[1.4vh] font-montserrat'>
          <div className='w-full h-[100%] flex-col justify-start items-center flex'>
            <div className='self-stretch h-[8.57vh] px-[2.4vh] py-[1.4vh] rounded-[2.5vh] justify-between items-center inline-flex'>
              <div className='w-[2.4vh] h-[2.4vh] relative'>
                <div className='w-[2.4vh] h-[2.4vh] left-0 top-0 absolute'>
                  <BackButton onClick={HandleClick} />
                </div>
              </div>
              <div className='px-[1.4vh] py-[1vh] rounded-[5.7vh] border-[0.2vh] border-outlining dark:border-outliningDark border-opacity-10 justify-center items-center gap-[1vh] flex'>
                <div className='text-center text-fontLightColor text-fontLightColorDark text-[1.67vh] font-semibold leading-tight'>
                  {assetDetail?.symbol} on
                </div>
                <div className='w-[2.4vh] h-[2.4vh] relative  backdrop-blur-[2.6vh] rounded-[0.5vh] overflow-hidden'>
                  <img src={assetDetail?.chainLogo} />
                </div>
              </div>
              <div
                ref={qrRef2}
                className='cursor-pointer z-[50]'
                onClick={() =>
                  handleQrClick(
                    currentAddress,
                    {
                      logo: assetDetail?.chainLogo || "",
                      chainName: assetDetail?.chainName || "",
                    },
                    [
                      {
                        logo: assetDetail?.chainLogo || "",
                        chainName: assetDetail?.chainName || "",
                      },
                    ]
                  )
                }
              >
                <QrButton />
              </div>
            </div>
            <div className='self-stretch  h-[72vh] py-[1vh] flex-col justify-start items-center flex'>
              <div className='self-stretch h-[37vh] px-[2.4vh] py-[1.4vh] rounded-[1.07vh] flex-col justify-center items-start gap-[2.85vh] flex'>
                <div className='self-stretch h-[17.6vh] flex-col justify-start items-center gap-[1.4vh] flex'>
                  <div className='rounded-[4.76vh] pt-[4vh] shadow flex-col justify-center items-center gap-2 flex'>
                    <div className='bg-primaryColor dark:bg-primaryDarkColor rounded-[7.1vh] backdrop-blur-[7.6vh] justify-start items-start gap-[1.4vh] inline-flex'>
                      <div className='w-[7.1vh] h-[7.1vh] rounded-[3vh] overflow-hidden relative'>
                        <img
                          className={`w-[7.1vh] h-[7.1vh] left-0 top-0 absolute overflow-hidden  ${
                            assetDetail?.symbol?.toUpperCase() === "FUSE"
                              ? "scale-[120%]"
                              : ""
                          }`}
                          src={assetDetail?.logoUrl || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='self-stretch h-[76px] flex-col justify-center items-center gap-[0.5vh] flex'>
                    <div className='self-stretch justify-center items-center gap-[1vh] inline-flex'>
                      <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-80 text-[2.4vh] font-semibold leading-tight'>
                        $
                        {showHistory === "All"
                          ? (eoavalueInUsd + aaValueInUsd)?.toFixed(3)
                          : showHistory === "AA"
                          ? aaValueInUsd?.toFixed(3)
                          : eoavalueInUsd?.toFixed(3)}
                      </div>
                    </div>
                    <div className='self-stretch justify-center items-center gap-[1vh] flex'>
                      <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[4.76vh] font-semibold leading-tight'>
                        {showHistory === "All"
                          ? (
                              handleBalance(eoaAssetBalance) +
                              handleBalance(aaAssetBalance)
                            )?.toFixed(5)
                          : showHistory === "AA"
                          ? handleBalance(aaAssetBalance)?.toFixed(5)
                          : handleBalance(eoaAssetBalance)?.toFixed(5)}
                        {assetDetail?.symbol}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex pt-[4vh] w-full items-center justify-center  z-[1000]'>
                  <AddressDropdown
                    logo={assetDetail?.chainLogo || ""}
                    chainName={assetDetail?.chainName || ""}
                    eoavalueInUsd={eoavalueInUsd}
                    aavalueInUsd={aaValueInUsd}
                    setShowHistory={setShowHistory}
                    setCurrentAddress={setCurrentAddress}
                  />
                </div>
                <div className='self-stretch py-[1.4vh] justify-center items-center gap-[16vh] inline-flex z-50'>
                  <div className='flex-col justify-center items-center gap-[1vh] flex cursor-pointer'>
                    <div
                      className='bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[4.76vh] w-[7.1vh] h-[7.1vh] dark:border-outliningDark flex-col justify-center items-center gap-[1.3vh] flex relative group'
                      onClick={handleBuyClick}
                    >
                      <div className='absolute rounded-[4.76vh] w-[7.1vh] h-[7.1vh] dark:bg-[#252525] bg-[#000000] group-hover:opacity-10 opacity-0' />
                      <div className='w-[6vh] h-[6vh] flex items-center justify-center'>
                        <WalletIcon
                          color='white'
                          width='3.33vh'
                          height='3.33vh'
                        />
                      </div>
                    </div>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                      Buy
                    </div>
                  </div>
                  <div
                    className='flex-col justify-center items-center gap-[1vh] inline-flex cursor-pointer'
                    onClick={handleSendClick}
                  >
                    <div className='rounded-[4.76vh] w-[7.1vh] h-[7.1vh] border-[0.36vh] bg-outlining dark:bg-outliningDark border-outlining dark:border-outliningDark flex-col justify-center items-center gap-[1.3vh] flex relative group'>
                      <div className=' flex w-0 h-0 items-center justify-center  dark:w-[6vh] dark:h-[6vh] translate-y-[0.5vh]'>
                        <SendIcon color='gray' width='5vh' height='5vh' />
                      </div>
                      <div className='dark:w-0 dark:h-0 w-[6vh] h-[6vh] flex items-center dark:invisible justify-center translate-y-[-0.5vh]'>
                        <SendIcon color='#7C7C7C' width='5vh' height='5vh' />
                      </div>
                    </div>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                      Send
                    </div>
                  </div>
                </div>
              </div>
              <div className='self-stretch min-h-[45.2vh] py-[2vh] rounded-[2vh] flex-col justify-start items-center gap-[1vh] flex overflow-y-auto overflow-x-hidden'>
                <div className='text-[1.67vh] w-full text-fontLightColor dark:text-fontLightColorDark px-[1.4vh] font-[600] py-[1.4vh] '>
                  ACTIVITY
                </div>
                {Object?.entries(groupedItems)?.map(([dateLabel, assets]) => (
                  <>
                    <div className='self-stretch text-fontLightColor dark:text-fontLightColorDark font-[600] text-[1.4vh]  leading-tight px-[1.4vh]'>
                      {dateLabel === "Yesterday" || dateLabel === "Today"
                        ? `${dateLabel}`
                        : `${dateLabel?.split(" ")[0]}, ${
                            dateLabel?.split(" ")[2]
                          } ${dateLabel?.split(" ")[1]} ${
                            dateLabel?.split(" ")[3]
                          }`}
                    </div>

                    {assets?.map((asset) => (
                      <AssetHistoryContainer asset={asset} />
                    ))}
                  </>
                ))}
                {/* {(Array.isArray(assetHistory) ? assetHistory : [])?.map(
                  (asset, index) => (
                    <AssetHistoryContainer asset={asset} />
                  )
                )} */}
              </div>
            </div>
          </div>
          <Opentria />
          {showQr && (
            <div
              ref={qrRef}
              className={` shadow-2xl  absolute top-[10vh] z-[1000] flex bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh]   ${
                showQr ? "" : "hidden"
              }`}
            >
              <QR
                item={qrAddress}
                activeChain={qrChain}
                networkItems={qrNetworkItems || []}
                logo={qrLogo}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
