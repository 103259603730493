import { useDispatch } from "react-redux"
import { AssetForTriaName } from "../../../../../rx.core"
import { updateCurrentSendToken } from "../../../../../rx.core"
import { use } from "i18next"
import { useEffect } from "react"
interface Props {
  cryptoItem: AssetForTriaName
  onClick: (item: AssetForTriaName) => void
}

export const AssetContainer: React.FC<Props> = ({ cryptoItem, onClick }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const payload = {
      tokenAddress: cryptoItem?.tokenAddress || "",
      chainName: cryptoItem?.chainName,
      qouteRate: cryptoItem?.quoteRate,
    }
    dispatch(updateCurrentSendToken(payload))
    // dispatch()
  }, [])

  return (
    <div
      className='self-stretch h-[50px] md:h-[72px] px-[14px] md:px-[20px] py-[8.4px] md:py-[12px] bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[14px] md:rounded-[21px] flex-col justify-center items-center gap-[5.6px] md:gap-[8px] flex cursor-pointer'
      onClick={() => onClick(cryptoItem)}
    >
      <div className='self-stretch justify-start items-center gap-[11.2px] md:gap-[16px] inline-flex'>
        <div className='grow shrink basis-0 h-[28px] md:h-[40px] justify-start items-center gap-[5.6px] md:gap-[8px] flex'>
          <div className='w-[28px] h-[28px] md:w-[40px] md:h-[40px]  rounded-[28px] md:rounded-[40px] relative'>
            {cryptoItem?.logoUrl ? (
              <div className='w-[28px] h-[28px] md:w-[40px] md:h-[40px] left-0 top-0 absolute bg-violet-600 rounded-[28px] md:rounded-[40px] backdrop-blur-[5px] flex-col justify-center items-center inline-flex overflow-hidden'>
                <img
                  className={`w-[28px] h-[28px] md:w-[40px] md:h-[40px] rounded-[19.6px] md:rounded-[28.39px] ${
                    cryptoItem?.name?.toUpperCase() === "FUSE"
                      ? "scale-[120%]"
                      : ""
                  }`}
                  src={cryptoItem?.logoUrl}
                  alt={`Logo of: ${cryptoItem?.name}`}
                />
              </div>
            ) : (
              <div className='w-[28px] h-[28px] md:w-[40px] md:h-[40px] left-0 top-0 absolute bg-violet-600 rounded-[28px] md:rounded-[40px] backdrop-blur-[5px] flex-col justify-center items-center inline-flex text-white font-[600] text-[16.8px] md:text-[24px]'>
                {cryptoItem?.name.charAt(0)}
              </div>
            )}

            <div className='w-[15.4px] h-[15.4px] md:w-[22px] md:h-[22px] left-[16.8px] top-[16.8px] md:left-[24px] md:top-[25px] absolute  backdrop-blur-[2.50px] flex-col justify-center items-center inline-flex '>
              <img
                className='w-[14px] h-[14px] rounded-[4px] overflow-hidden md:w-[20px] md:h-[20px]'
                src={cryptoItem?.chainLogo}
                alt={`Logo of: ${cryptoItem?.chainName}`}
              />
            </div>
          </div>
          <div className='grow shrink basis-0 flex-col justify-start items-start gap-[2.8px] md:gap-[4px] inline-flex'>
            <div className='self-stretch justify-start items-center flex gap-[0.5vh]'>
              <div className="text-center text-fontPrimaryColor dark:text-text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[11.2px] md:text-[16px] font-semibold font-'Montserrat' leading-tight">
                {cryptoItem?.symbol}
              </div>
              {cryptoItem?.wallet?.type === "AA" && (
                <img
                  src='/images/aaverifiedbatch.svg'
                  className='w-[3vh] h-[3vh]'
                />
              )}
            </div>
          </div>
        </div>
        <div className='grow shrink basis-0 flex-col justify-center items-end gap-[2.8px] md:gap-[4px] inline-flex'>
          <div className="text-center text-fontPrimaryColor dark:text-text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[11.2px] md:text-[16px] font-semibold font-'Montserrat' leading-tight">
            ${cryptoItem?.balanceInUSD?.toFixed(2)}
          </div>
          <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-[10px] md:text-[14px] font-semibold font-'Montserrat' leading-tight">
            {typeof cryptoItem?.balanceInTokens === "string"
              ? parseFloat(cryptoItem?.balanceInTokens)?.toFixed(2)
              : cryptoItem?.balanceInTokens?.toFixed(2)}{" "}
            {cryptoItem?.symbol}
          </div>
        </div>
      </div>
    </div>
  )
}
