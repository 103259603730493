interface Props {
  color: string
  width: string
  height: string
}

export const SwapIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M14.4 8.7083L17.5 5.60828L14.4 2.5083'
        stroke='white'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 5.60828H17.5'
        stroke='white'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.59998 11.2917L2.5 14.3918L5.59998 17.4917'
        stroke='white'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 14.3917H2.5'
        stroke='white'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
