import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { UserPrefState } from "./userpref.model"
import { CustomChainDataType, CustomTokenDataType } from "../../../types"

const INITIAL_STATE: UserPrefState = {
  customChains: [],
  customTokens: [],
}

export const userPrefSlice = createSlice({
  name: "userpref",
  initialState: INITIAL_STATE,
  reducers: {
    updateCustomChains: (
      state,
      action: PayloadAction<CustomChainDataType[]>
    ) => {
      state.customChains = action.payload
    },
    updateCustomTokens: (
      state,
      action: PayloadAction<CustomTokenDataType[]>
    ) => {
      state.customTokens = action.payload
    },
    updateCurrentEditToken: (
      state,
      action: PayloadAction<CustomTokenDataType>
    ) => {
      state.currentEditToken = action.payload
    },
    updateCurrentEditChain: (
      state,
      action: PayloadAction<CustomChainDataType>
    ) => {
      state.currentEditChain = action.payload
    },
  },
})

export const {
  updateCustomChains,
  updateCustomTokens,
  updateCurrentEditChain,
  updateCurrentEditToken,
} = userPrefSlice.actions
