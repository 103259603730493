import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../package/ui.common"
import {
  CustomChainDataType,
  GetAssetDetailsResponse,
  RootState,
  updateApiTimer,
  updateAssetDetailCache,
} from "../package/rx.core"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {} from "../package/rx.core/src/redux/features/assethistory"

export const useFetchAssetDetails = () => {
  const { getAssetDetails } = useTriaUser()
  const dispatch = useDispatch()
  const { chainName, tokenAddress, isCustomToken } = useSelector(
    (store: RootState) => store.Asset.fetchParam
  )
  const [isLoading2, setIsLoading2] = useState<boolean>(false)
  // const tokenAddress = useSelector(
  //   (store: RootState) => store.Asset.fetchParam.tokenAddress
  // )
  const customChains = useSelector(
    (store: RootState) => store.userpref.customChains
  )
  const { time, prevFetch } = useSelector(
    (store: RootState) => store?.ApiLogs?.apiTimer?.getAssetDetail
  )
  const [triaName, setTriaName] = useState<string>()

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchAssetDetails = async (chainName: string, triaName: string) => {
    if (triaName) {
      if (
        prevFetch.chainName == chainName &&
        prevFetch.tokenAddress == tokenAddress &&
        Date.now() - time < 20000
      ) {
      } else {
        try {
          setIsLoading2(true)
          let chain: CustomChainDataType | undefined
          if (isCustomToken) {
            chain = customChains.find((chain) => {
              if (chain?.chainName === chainName) {
                return chain
              }
            })
          }
          console.log(
            "assetDetail log",
            isCustomToken,
            chain,
            chainName,
            tokenAddress
          )
          const response = await getAssetDetails({
            chainName,
            tokenAddress,
            chain,
          })
          dispatch(
            updateApiTimer({
              apiEndpoint: "getAssetDetail",
              time: Date.now(),
              prevFetch: { chainName, tokenAddress },
            })
          )
          dispatch(updateAssetDetailCache(response))
        } catch (error) {
          console.error(error)
          const { message } = error as Error
          return Promise.reject(String(message || error))
        } finally {
          setIsLoading2(false)
        }
      }
    }
  }

  useEffect(() => {
    if (triaName) fetchAssetDetails(chainName || "", triaName || "")
  }, [triaName])
  return isLoading2
}
