import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../package/ui.common"
import {
  AvatarItem,
  RootState,
  store,
  updateUserAvatar,
} from "../package/rx.core"

export const useFetchUserAvatar = () => {
  const [accessToken, setAccessToken] = useState<string>()
  const { getAvatar } = useTriaUser()
  const [avatar, setAvatar] = useState<AvatarItem>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [triaName, setTriaName] = useState<string>()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchedOnce = useSelector((store: RootState) => store.User.fetchedOnce)
  const prevAvatar = useSelector((store: RootState) => store?.User?.avatar)
  useEffect(() => {
    const storedData = localStorage.getItem("tria.accessToken")
    if (storedData !== null) {
      setAccessToken(storedData)
    } else {
      console.log("Can't find accesstoken!")
    }
  }, [])

  useEffect(() => {
    if (triaName) {
      setUrl(
        `${process.env.REACT_APP_SDK_BASE_URL}/api/v1/user/getAvatarByTriaName?triaNames=${triaName}`
      )
    }
  }, [triaName])

  useEffect(() => {
    const fetchUserAvatar = async () => {
      if (triaName && url) {
        try {
          setIsLoading(true)
          const response = await fetch(url)
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
          const data = await response.json()
          if (
            data &&
            data.response &&
            data.response[triaName] &&
            data.response[triaName]?.length > 0
          ) {
            const userData = data.response[triaName][0]
            if (userData.avatar && userData.background) {
              dispatch(
                updateUserAvatar({
                  avatar: userData.avatar,
                  background: userData.background,
                })
              )
            }
          }
        } catch (error) {
          console.error(error)
          const { message } = error as Error
          return Promise.reject(String(message || error))
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchUserAvatar()
  }, [triaName, url])
  return isLoading
}
