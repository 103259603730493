import React, { useEffect, useState } from "react"
import { useFetchAssets } from "../../../../../hooks"
import {
  AccordionAssetsForATriaName,
  AssetForTriaName,
} from "../../../../rx.core"
import { Accordion } from "./Accordion" // Import your Accordion component
import { useSelector } from "react-redux"
import { RootState } from "../../../../rx.core"
import HomeCrypto from "../../components/LoadersPages/HomeCrypto"

interface GroupedAssets {
  [key: string]: AccordionAssetsForATriaName[]
}

export const Crypto: React.FC = () => {
  const [groupedAssets, setGroupedAssets] = useState<GroupedAssets>({})
  const [lowBalanceGroup, setLowBalanceGroup] = useState<GroupedAssets>({})
  const isLoading = useFetchAssets()
  const [appDomain, setAppDomain] = useState<string>()

  const fromAssets = useSelector((store: RootState) => store.User?.userAssets)

  useEffect(() => {
    const updatedGroupedAssets: {
      [key: string]: AccordionAssetsForATriaName[]
    } = {}
    console.log("from assets", fromAssets)
    fromAssets.forEach((asset) => {
      // Define a unique identifier based on asset characteristics
      console.log(asset)
      const assetIdentifier = `${asset?.name}-${asset?.symbol}-${asset?.chainName}-${asset?.tokenAddress}`

      // Check if this specific asset variant already exists
      const existingAssetIndex = updatedGroupedAssets[asset?.name]?.findIndex(
        (a) =>
          `${a?.name}-${a?.symbol}-${a?.chainName}-${a?.tokenAddress}` ===
          assetIdentifier
      )

      if (existingAssetIndex !== undefined && existingAssetIndex > -1) {
        // If found, sum the balances
        updatedGroupedAssets[asset?.name][
          existingAssetIndex
        ].aabalanceInTokens +=
          asset?.wallet?.type === "AA" ? asset?.balanceInTokens : 0
        updatedGroupedAssets[asset?.name][
          existingAssetIndex
        ].eoabalanceInTokens +=
          asset?.wallet?.type === "EOA" ? asset?.balanceInTokens : 0
        updatedGroupedAssets[asset.name][existingAssetIndex].eoabalanceInUSD +=
          asset?.wallet?.type === "EOA" ? asset?.balanceInUSD : 0
        updatedGroupedAssets[asset.name][existingAssetIndex].aabalanceInUSD +=
          asset?.wallet?.type === "AA" ? asset?.balanceInUSD : 0
      } else {
        // If not found, initialize array if necessary and push new asset
        if (!updatedGroupedAssets[asset.name]) {
          updatedGroupedAssets[asset.name] = []
        }

        const newAsset: AccordionAssetsForATriaName = {
          name: asset?.name,
          symbol: asset?.symbol,
          logoUrl: asset?.logoUrl,
          chainLogo: asset?.chainLogo,
          chainName: asset?.chainName,
          balanceOfTokensInUnits: asset?.balanceOfTokensInUnits,
          decimals: asset?.decimals,
          aabalanceInTokens:
            asset?.wallet?.type === "AA" ? asset?.balanceInTokens : 0,
          eoabalanceInTokens:
            asset?.wallet?.type === "EOA" ? asset?.balanceInTokens : 0,
          eoabalanceInUSD:
            asset?.wallet?.type === "EOA" ? asset?.balanceInUSD : 0,
          aabalanceInUSD:
            asset?.wallet?.type === "AA" ? asset?.balanceInUSD : 0,
          quoteRate: asset?.quoteRate,
          tokenAddress: asset?.tokenAddress,
          percentChangein24hr: asset?.percentChangein24hr,
          isCustomToken: asset?.isCustomToken,
        }
        console.log(newAsset)
        updatedGroupedAssets[asset.name].push(newAsset)
      }
    })

    setGroupedAssets(updatedGroupedAssets)
  }, [fromAssets])

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setAppDomain(parsedData?.appDomain)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  return (
    <div className='w-full h-[44vh] rounded-[2.7vh] flex-col justify-start items-center gap-[1.4vh] flex'>
      <div className='self-stretch px-[3.4vh] pt-[2vh] rounded-[2.7vh] flex-col justify-center items-center gap-[1.4vh] flex'>
        <div className='self-stretch justify-start items-center gap-[2.7vh] inline-flex'>
          <div className='justify-start items-center gap-[1.4vh] flex'>
            <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-semibold font-montserrat leading-normal'>
              Crypto
            </div>
          </div>
        </div>
      </div>
      {isLoading && <HomeCrypto />}
      {!isLoading && fromAssets && (
        <div className='w-full overflow-y-auto mb-[2vh]'>
          {/* {Object.keys(groupedAssets)?.length > 0 && ( */}
          <div className='w-full h-full'>
            <Accordion groupedAssets={groupedAssets} />
            <Accordion groupedAssets={lowBalanceGroup} />
          </div>
          {/* )} */}
        </div>
      )}
      {!isLoading &&
        !Object.keys(groupedAssets)?.length &&
        !Object.keys(lowBalanceGroup)?.length && (
          <div className='scrollbar-thin w-full scrollbar-thumb-gray-300 scrollbar-track-stone-100 overflow-y-auto flex flex-col items-center justify-center h-[38vh] gap-[1.4vh]'>
            <img
              src='./images/nocryptodark.svg'
              className='w-[17.85vh] h-[17.85vh]'
            />
            <p className='font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark'>
              You have no crypto right now
            </p>
          </div>
        )}
    </div>
  )
}
