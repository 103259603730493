import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  TokenInfo,
  TransactionAppState,
  TransactionState,
} from "./transaction.model"
import { AvatarItem } from "../../../types"
import { access } from "fs"

const initialState: TransactionState = {
  param: {
    qouteRate: 0,
    senderBalance: 0,
    senderName: "",
    senderAddress: "",
    recepientName: "",
    senderLogo: {
      avatar: "",
      background: "",
    },
    recepientLogo: "",
    chainLogo: "",
    chainName: "",
    appLogo: "",
    appDomain: "",
    accessToken: "",
    darkMode: true,
    tokenName: "",
    tokenLogo: "",
    tokenAddress: "",
    type: "EOA",
    isNativeToken: true,
    isCustomToken: false,
  },
}

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    // updateCurrentRoute:( state, action:PayloadAction<string>) =>{
    //  state.currentRoute = action.payload;
    // },
    // updatePreviousRoute:( state, action:PayloadAction<string>) =>{
    //  state.previousRoute = action.payload;
    // },
    updateSenderAvatar: (state, action: PayloadAction<AvatarItem>) => {
      state.param.senderLogo = action.payload
    },
    updateTokenInfo: (state, action: PayloadAction<TokenInfo>) => {
      state.param.qouteRate = action.payload.qouteRate
      state.param.senderBalance = action.payload.senderBalance
      state.param.tokenName = action.payload.tokenName
      state.param.tokenLogo = action.payload.tokenLogo
      state.param.chainName = action.payload.chainName
      state.param.chainLogo = action.payload.chainLogo
      state.param.tokenAddress = action.payload.tokenAddress
      state.param.senderAddress = action.payload.senderAddress
      state.param.type = action.payload.type
      state.param.isNativeToken = action.payload.isNativeToken
      state.param.isCustomToken = action.payload.isCustomToken
    },
  },
})

export const { updateSenderAvatar, updateTokenInfo } = transactionSlice.actions
