import React, { useState, useEffect, useRef } from 'react'
import { Opentria, useTriaUser } from '../../package/ui.common'
import { AssetForTriaName, NetworkItem, RootState, useToast } from '../../package/rx.core'
import { useNavigate } from 'react-router-dom'
import ToSelectDropDown from '../../package/ui.common/src/components/Swap/ToSelectDropDown'
import FromSelectDropDown from '../../package/ui.common/src/components/Swap/FromSelectDropDown'
import { useSelector } from 'react-redux'
export const walletType = { embedded: true }
const AUTH_URL = process.env.REACT_APP_AUTH_URL

interface ChainId {
  [key: string]: number
}

const ChainId: ChainId = {
  MUMBAI: 80001,
  POLYGON: 137,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  FANTOM: 250,
  GNOSIS: 100,
  FUSE: 122,
  ETH: 1,
  BINANCE: 56,
}

export const Swap: React.FC = () => {
  const { openToastMessage, closeToastMessage, setToastData } = useToast()
  const [error, setError] = useState<string>()
  const [enteredAmountInUsdValue, setEnteredAmountInUsdValue] =useState<string>('')
  const [tokenSelectLifi, setTokenSelectLifi] = useState<AssetForTriaName>()
  const [chainData, setChainData] = useState<any>()
  const [chainIndexSelected, setChainIndexSelected] = useState(0)
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [toChainDataSelected, setToChainDataSelected] = useState()
  const [chainDataSelected, setChainDataSelected] = useState<any>()

  // to dropdown states

  const [toTokenSelectLifi, setToTokenSelectLifi] = useState(null)
  const [toChainData, setToChainData] = useState([])
  const [toChainIdSelected, setToChainIdSelected] = useState<number>(1)
  const [toChainIndexSelected, setToChainIndexSelected] = useState<number>(0)
  const [fromChainIdSelected, setFromChainIdSelected] = useState<number>()

  const userStatus = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  )
  const networkItems = useSelector(
    (store: RootState) => store?.Constants?.NetworkItems
  )

  useEffect(() => {
    if (chainData && chainData.length>0 && !chainDataSelected) {
      const result = networkItems.find(
        (network: any) => network?.chainName === userStatus?.chainName
      )

      const matchedItem = chainData?.find(
        (item: any) => item?.name?.toUpperCase() === result?.chainName?.toUpperCase()
      );
      setChainDataSelected(matchedItem||chainData?.[0])
    }

  }, [networkItems, userStatus?.chainName, chainData, chainDataSelected, userStatus])

  console.log('id----->', chainDataSelected)

  const handleAmountUSDValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!tokenSelectLifi) {
      setError('Please Select Asset')
      return
    }
    const inputValue = e.target.value

    const validDecimalRegex = /^[0-9]*\.?[0-9]*$/

    if (inputValue === '' || validDecimalRegex.test(inputValue)) {
      setEnteredAmountInUsdValue(inputValue)
    } else {
    }
  }

  const getRoutes = async () => {
    try {
      let id
      if (tokenSelectLifi) id = ChainId[tokenSelectLifi?.name]

      console.log(
        'chainSelected',
        id,
        chainDataSelected,
        chainData,
        chainData[chainIndexSelected]
      )

      const payload = {
        fromChainData: chainDataSelected,
        fromTokenData: tokenSelectLifi,
        toChainData: chainDataSelected,
        toTokenData: toTokenSelectLifi,
        fromChainId: chainDataSelected?.id,
        amount: enteredAmountInUsdValue,
        toChainId: chainDataSelected?.id,
        fromWallet: true,
      }
      console.log('payLoad', payload)

      const encodedParams = btoa(JSON.stringify(payload))

      window.open(`${AUTH_URL}/swap/${encodedParams}`, '_self')
      // window.open(`http://localhost:3000/swap/${encodedParams}`, '_blank');

      console.log(`${AUTH_URL}/swap/${encodedParams}`)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setError('')
    const numericAmountValue = parseFloat(enteredAmountInUsdValue)

    if (
      tokenSelectLifi?.balanceInTokens &&
      numericAmountValue >= tokenSelectLifi?.balanceInTokens
    ) {
      setError('Insufficient funds')
      setDisableButton(false)
    }
  })

  useEffect(() => {
    if (error) {
      setToastData({ status: 'error', title: error })
      openToastMessage()
      setTimeout(closeToastMessage, 4000)
    }
  }, [error ])

  const navigate = useNavigate()
  useEffect(() => {
    if (
      parseFloat(enteredAmountInUsdValue) > 0 &&
      tokenSelectLifi != null &&
      toTokenSelectLifi != null &&
      !error
    ) {
      setDisableButton(true)
    }
  }, [enteredAmountInUsdValue, tokenSelectLifi, toTokenSelectLifi, error])

  const setEnteredAmountInUsd = async (percentChange: number) => {
    if (!tokenSelectLifi) {
      return setError('please select assest')
    }
    setEnteredAmountInUsdValue(
      (tokenSelectLifi?.balanceInTokens * percentChange).toFixed(6)
    )
  }

  const calculateTextSize = () => {
    if (enteredAmountInUsdValue.length < 3) {
      return 'text-[12.24vh]';
    } else if (enteredAmountInUsdValue.length < 4) {
      return 'text-[11vh]';
    } else if (enteredAmountInUsdValue.length < 5) {
      return 'text-[9.6vh]';
    }
    else {
      return 'text-[8.16vh]';
    }

  };

  return (
    <div>
      <div className='w-[53vh] h-[100vh] relative'>
        <div className='left-0 top-0 absolute'>
          <div className='left-0  absolute bg-white dark:bg-stone-950 rounded-[2.4vh] shadow -2 -zinc-500'>
            <div className='p-[1.92vh] left-0 top-0 absolute flex-col justify-between items-center inline-flex'>
              <div className='w-[50vh] h-[84.4vh] flex-col justify-start items-center flex'>
                <div className='self-stretch h-[8.6vh] rounded-2xl flex-col justify-center items-center gap-[0.96vh] flex'>
                  <div className='self-stretch h-[8.6vh] flex-col justify-center items-center gap-[0.96vh] flex'>
                    <div className='self-stretch h-[8.6vh] px-[1.92vh] py-[0.96vh] rounded-[2.5vh] flex-col justify-center items-center gap-[0.96vh] flex'>
                      <div className='self-stretch justify-start items-center gap-[0.96vh] inline-flex'>
                        <div className='w-[2.2vh] h-[2.2vh] relative'>
                          <div
                            className='w-[2.2vh] h-[2.2vh] left-0 top-0 absolute cursor-pointer'
                            onClick={() => {
                              navigate('/home')
                            }}
                          >
                            <img
                              src='/icons/arrow-right2.svg'
                              className='dark:visible invisible w-0 dark:w-[2.6vh] rotate-180'
                            />
                            <img
                              src='/icons/arrow-left.svg'
                              className='dark:invisible visible dark:w-0 '
                            />
                          </div>
                        </div>
                        <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.48vh] inline-flex'>
                          <div className='self-stretch justify-start items-center gap-[0.96vh] inline-flex'>
                            <div className='text-center font-montserrat dark:text-neutral-50 md:text-xl text-lg font-semibold  leading-normal'>
                              Convert
                            </div>
                            <div className='text-center font-montserrat dark:text-zinc-500 text-fontPrimaryColor text-opacity-30 md:text-base text-sm font-semibold  leading-tight'>
                              (Swap)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='self-stretch py-[1.92vh] flex-col justify-start items-center gap-[0.96vh] flex'>
                  <div className='self-stretch pb-[3.84vh] rounded-2xl -2 flex-col justify-start items-center gap-[0.96vh] flex'>
                    <div className='h-[31.9vh] pb-[0.96vh] pb-[3.48vh flex-col justify-end items-center gap-[0.96vh] inline-flex'>
                      <div className='self-stretch justify-center items-center gap-[0.96vh] inline-flex'>
                        <div className='w-[4.8vh] h-[4.8vh] relative'>
                          <div className='w-[4.8vh] h-[4.8vh] left-0 top-[.36vh] absolute dark:bg-zinc-800 bg-bgOfCircle rounded-[4.8vh] overflow-hidden'>
                            <img src={tokenSelectLifi?.logoUrl || ''} className={`${tokenSelectLifi?.name.toUpperCase() === 'FUSE'
                              ? 'scale-[124%]'
                              : ''
                              } `} />
                          </div>
                        </div>
                        <div className='justify-center items-baseline flex'>
                          <input
                            type='text'
                            className={`text-start font-['e-Ukraine' font-bold bg-primaryColor dark:bg-primaryDarkColor w-[25vh] dark:text-white  focus:outline-none focus:border-none focus:ring-0 ${calculateTextSize()}`}
                            placeholder='0'
                            value={enteredAmountInUsdValue}
                            onChange={handleAmountUSDValueChange}
                          />
                        </div>
                      </div>
                      <div className='self-stretch justify-center items-start gap-[.48vh] inline-flex'>
                        <div className='text-center text-zinc-500  md:text-xl text-lg placeholder:0 font-semibold  leading-normal'>
                          $
                          {enteredAmountInUsdValue !== '' && tokenSelectLifi
                            ? (
                              (tokenSelectLifi?.balanceInUSD /
                                tokenSelectLifi?.balanceInTokens) *
                              parseFloat(enteredAmountInUsdValue)
                            ).toFixed(4)
                            : 0}
                        </div>
                      </div>
                      <div className='self-stretch h-[8.64vh] py-[1.92vh] flex-col justify-center items-center gap-[0.96vh] flex'>
                        <div className='justify-start items-center inline-flex'>
                          <div
                            className='h-[4.8vh] px-[2.4vh] py-[1.44vh] dark:bg-bgOfmax  bg-fontColorNftCardDark rounded-tl-2xl rounded-bl-2xl shadow border dark:border-navigatorBackgroundActiveDark justify-center items-center gap-[.48vh] flex  cursor-pointer'
                            onClick={() => {
                              setEnteredAmountInUsd(0.25)
                            }}
                          >
                            <div className='text-center dark:text-white text-fontLightColor dark:text-opacity-75 md:text-base text-sm font-medium  leading-none'>
                              25%
                            </div>
                          </div>
                          <div
                            className='h-[4.8vh] px-[2.4vh] py-[1.44vh] dark:bg-bgOfmax bg-fontColorNftCardDark shadow border dark:border-navigatorBackgroundActiveDark justify-center items-center gap-[.48vh] flex cursor-pointer'
                            onClick={() => {
                              setEnteredAmountInUsd(0.5)
                            }}
                          >
                            <div className='text-center dark:text-white text-fontLightColor dark:text-opacity-75 md:text-base text-sm font-medium  leading-none '>
                              50%
                            </div>
                          </div>
                          <div
                            className='h-[4.8vh] px-[2.4vh] py-[1.44vh]  dark:bg-bgOfmax bg-fontColorNftCardDark rounded-tr-2xl rounded-br-2xl shadow border dark:border-navigatorBackgroundActiveDark items-center justify-center  gap-[.48vh] flex cursor-pointer'
                            onClick={() => {
                              setEnteredAmountInUsd(1)
                            }}
                          >
                            <div className='text-center font-montserrat dark:text-white text-fontDarkColor text-opacity-90 md:text-base text-sm font-medium  leading-none '>
                              MAX
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='self-stretch h-[20.2vh] flex-col justify-start items-center gap-[1.44vh] flex'>
                      <FromSelectDropDown
                        chainData={chainData}
                        chainIndexSelected={chainIndexSelected}
                        tokenSelectLifi={tokenSelectLifi}
                        setChainData={setChainData}
                        setChainIndexSelected={setChainIndexSelected}
                        setTokenSelectLifi={setTokenSelectLifi}
                        overlayVisible={overlayVisible}
                        setOverlayVisible={setOverlayVisible}
                        toChainData={toChainData}
                        fromChainIdSelected={fromChainIdSelected}
                        setFromChainIdSelected={setFromChainIdSelected}
                        setChainDataSelected={setChainDataSelected}
                        chainDataSelected={chainDataSelected}
                      />
                      <ToSelectDropDown
                        toChainData={toChainData}
                        toChainIndexSelected={toChainIndexSelected}
                        toTokenSelectLifi={toTokenSelectLifi}
                        toChainIdSelected={toChainIdSelected}
                        setToChainData={setToChainData}
                        setToChainIndexSelected={setToChainIndexSelected}
                        setToTokenSelectLifi={setToTokenSelectLifi}
                        setToChainIdSelected={setToChainIdSelected}
                        overlayVisible={overlayVisible}
                        setOverlayVisible={setOverlayVisible}
                        toChainDataSelected={toChainDataSelected}
                        setToChainDataSelected={setToChainDataSelected}
                        fromChainIdSelected={fromChainIdSelected}
                        chainDataSelected={chainDataSelected}
                      />

                      <div className='px-[1.26vh] py-[1.44vh] left-[25vh] top-[52.6vh] absolute dark:bg-swapIcon bg-swapIconLight rounded-[1.6vh] shadow justify-center items-center gap-[1vh] inline-flex'>
                        <div className='w-[3.24vh] h-[3.24vh] relative'>
                          <img
                            src='/icons/swap.svg'
                            className='dark:visible invisible w-0 dark:w-[3.24vh]'
                          />
                          <img
                            src='/icons/swap-dark.svg'
                            className='dark:invisible visible dark:w-0'
                          />
                          <div className='w-[3.24vh] h-[3.24vh] left-0 top-0 absolute'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='self-stretch h-[12.6vh] flex-col justify-center items-center gap-[.96vh] flex'>
                <div className='self-stretch h-[6.5vh] pb-[5.4vh] flex-col justify-center items-center gap-[1.92vh] flex  '>
                  <div className='self-stretch justify-center items-center gap-6 inline-flex '>
                    <div
                      className={`h-[6.5vh] p-5 bg-gradient-to-r  rounded-[6.5vh] justify-center items-center flex w-[25.44vh] cursor-pointer ${!disableButton
                        ? 'bg-zinc-600  text-opacity-30 text-white disabled'
                        : 'bg-gradient-to-r from-violet-400 to-indigo-500'
                        }`}
                      onClick={() => {
                        if (disableButton) {
                          getRoutes()
                        }
                      }}
                    >
                      <div className='justify-center items-center flex'>
                        <div className='text-center font-montserrat text-white md:text-xl text-base font-semibold  leading-normal'>
                          Review
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='self-stretch dark:h-0 dark:w-0 h-[9.2vh] flex-col justify-center items-center dark:hidden gap-[1vh] flex rounded-b-[1vh] absolute w-full bottom-[0vh] nft-footer-bg-gradient left-0'>
                  <Opentria />
                </div>
                <div className=' w-0 h-0 dark:h-[9.2vh] flex-col justify-center items-center gap-[1vh] hidden dark:flex  rounded-b-0 absolute dark:w-full bottom-[0vh] left-0'>
                  <Opentria />
                </div>
              </div>
            </div>
          </div>
        </div>

   
      </div>
      {overlayVisible && (

<>
  <div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '19.8vh',
    backdropFilter: 'blur(.48vh)',
    WebkitBackdropFilter: 'blur(.48vh)',
  }} />
  <div style={{
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '20vh', 
    backdropFilter: 'blur(.48vh)',
    WebkitBackdropFilter: 'blur(.48vh)',
  }} />

</>
)}
    </div>
  )
}
