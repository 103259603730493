import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  HistoryItem,
  RootState,
  copyToClipboard,
  formatAddress,
  formatDisplayDateAndTime,
  formatNumberDisplay,
  updateCurrentRoute,
} from "../../../../rx.core"
import {
  CopyButton,
  CryptoTransactionDetails,
  NftTransactionDetails,
  Opentria,
} from "../../components"
import { BackButton } from "../../components/Buttons/BackButton"
import { useDispatch, useSelector } from "react-redux"
import { useResolveTriaName } from "../../../../../hooks"

export const TransactionDetailCrypto: React.FC = () => {
  let navigate = useNavigate()
  function HandleClick() {
    navigate("/activity")
  }

  const [dateTime, setDateTime] = useState(["", ""])
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const location = useLocation()
  const transaction: HistoryItem = location.state && location.state?.selected
  useEffect(() => {
    const date = formatDisplayDateAndTime(new Date(transaction?.timestamp))
    setDateTime(date)
    console.log("transaction --->", transaction)
  }, [transaction])

  return (
    <div className='w-[53vh] h-[100vh] px-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh]  py-[2vh] flex-col justify-between items-center inline-flex relative'>
      <div className='self-stretch h-[74.5vh] flex-col justify-start items-center flex '>
        <div className='self-stretch h-[8.57vh] px-[1vh] py-[1.4vh] rounded-[2.5vh] justify-center items-center gap-[1vh] inline-flex z-50'>
          <BackButton onClick={HandleClick} />
          <div className='grow shrink basis-0 h-[4.3vh] justify-center items-center gap-[2vh] flex'>
            <div className='px-[1.4vh] py-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[5.7vh] border-zinc-100 border-[0.15vh] justify-center items-center gap-[1vh] flex'>
              <div className='text-center text-fontLightColor dark:text-fontLightColorDark  text-[1.4vh] font-semibold font-Montserrat leading-tight'>
                {transaction?.desc?.action} on
              </div>
              <div className='w-[2.4vh] h-[2.4vh] rounded-[0.4vh] backdrop-blur-[2.50px] justify-center items-center flex overflow-hidden'>
                <img src={transaction?.chainLogo} />
              </div>
            </div>
          </div>
          <div className='w-[2.1vh] h-[2.1vh] relative' />
        </div>
        <div className='self-stretch h-[66vh] py-[1vh] flex-col justify-start items-center gap-[1vh] flex'>
          <div className='self-stretch px-[2.4vh] py-[2vh] rounded-[2vh] justify-center items-center inline-flex '>
            <a
              className='justify-start items-center gap-[0.5vh] flex'
              href={transaction?.viewInExplorer}
              target='_blank'
            >
              <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-normal'>
                View on explorer
              </div>
              <div className='w-[2.1vh] h-[2.1vh] relative'>
                <div className='origin-top-left  w-[2.1vh] h-[2.1vh]'>
                  <img src='/icons/arrow-up.svg'></img>
                </div>
              </div>
            </a>
          </div>
          {transaction?.desc?.decimals === 0 ? (
            <NftTransactionDetails transaction={transaction} />
          ) : (
            <CryptoTransactionDetails transaction={transaction} />
          )}

          <div className='self-stretch h-[30.6vh] px-[2.4vh] pt-[4vh] rounded-[2vh] flex-col justify-center items-center gap-[1vh] flex'>
            <div className='self-stretch py-[1.4vh] justify-start items-start gap-[2vh] inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[1vh] inline-flex'>
                <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                  <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.1vh] font-semibold font-Montserrat leading-snug'>
                    Confirmed on
                  </div>
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[1vh] inline-flex'>
                <div className='self-stretch justify-end items-center gap-[1vh] inline-flex'>
                  <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[2.1vh] font-normal font-Montserrat leading-snug'>
                    {dateTime[0]}
                  </div>
                </div>
                <div className='self-stretch justify-end items-center gap-[1vh] inline-flex'>
                  <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh] font-normal font-Montserrat leading-tight'>
                    {dateTime[1]}
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch py-[1.4vh] justify-start items-start gap-[2vh]  inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[1vh] inline-flex'>
                <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                  <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.1vh] font-semibold font-Montserrat leading-snug'>
                    Network Fee
                  </div>
                  <div className='w-[2.1vh] h-[2.1vh] relative'>
                    <div className='w-[2.1vh] h-[2.1vh] left-0 top-0 absolute'>
                      <img src='/icons/Vector.svg'></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[1vh] inline-flex'>
                <div className='self-stretch justify-end items-center gap-[1vh] inline-flex'>
                  <p
                    className={`${
                      transaction?.wallet?.type === "AA" ? "line-through" : ""
                    }  text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[2.1vh] font-normal font-Montserrat leading-snug`}
                  >
                    ${transaction?.feesInUsd?.toFixed(5)}{" "}
                  </p>
                  {transaction?.wallet?.type === "AA" && (
                    <p className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-40 text-[2.1vh] font-normal font-Montserrat leading-snug'>
                      Free
                    </p>
                  )}
                </div>
                <div className='self-stretch justify-end items-center gap-[1vh] inline-flex'>
                  {transaction?.wallet?.type === "EOA" ? (
                    <p className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh] font-normal font-Montserrat leading-[16.80px]'>
                      {(transaction?.feesInEth || 0)?.toFixed(5)}{" "}
                      {transaction?.desc?.assetSymbol}
                    </p>
                  ) : (
                    <div className='flex items-center justify-between gap-[0.5vh]'>
                      <p className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh] font-normal font-Montserrat leading-[16.80px]'>
                        Sponsored By Tria{" "}
                      </p>
                      <img src='/images/sponsoredBatch.svg' alt='spons' />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='self-stretch py-[1.4vh] justify-start items-start gap-[2vh] inline-flex'>
              <div className='grow shrink basis-0 h-[2.6vh] justify-start items-center gap-[1vh] flex'>
                <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.1vh] font-semibold font-Montserrat leading-snug'>
                  Total Cost
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-center items-end gap-[0.5vh] inline-flex'>
                <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[2.1vh] font-medium font-Montserrat leading-snug'>
                  $
                  {(
                    transaction?.desc?.valueInUsd + transaction?.feesInUsd
                  )?.toFixed(6)}
                </div>
                <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh] font-normal font-Montserrat leading-tight'>
                  {formatNumberDisplay(
                    transaction?.desc?.value
                      ? parseFloat(transaction?.desc?.value) /
                          10 ** transaction?.desc?.decimals +
                          (transaction?.feesInEth || 0)
                      : 0,
                    5
                  )}{" "}
                  <span>{transaction?.desc?.assetSymbol}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-[2.3vh] absolute'>
        <Opentria />
      </div>
    </div>
  )
}

export default TransactionDetailCrypto
