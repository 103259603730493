import { useEffect, useState } from "react"
import { useTriaUser } from "../package/ui.common"
import {
  Conversation,
  RootState,
  updateApiTimer,
  updateConversationCache,
} from "../package/rx.core"
import { useDispatch, useSelector } from "react-redux"

export const useFetchOnChainConversation = () => {
  const { getOnChainConversation } = useTriaUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { prevWithTriaName, time } = useSelector(
    (store: RootState) => store.ApiLogs?.apiTimer?.getOnChainConversation
  )

  const fetchCoversation = async (withName: string, triaName: string) => {
    if (prevWithTriaName == withName && Date.now() - time < 30000) {
    } else {
      try {
        setIsLoading(true)
        const response = await getOnChainConversation(withName, triaName)
        dispatch(updateConversationCache(response))
        dispatch(
          updateApiTimer({
            apiEndpoint: "getOnChainConversation",
            time: Date.now(),
            prevWithTriaName: withName,
          })
        )
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      } finally {
        setIsLoading(false)
      }
    }
  }

  return { fetchCoversation, isLoading }
}
