import React, { useEffect, useState } from "react"
import { useTriaUser } from "../../contexts"
import { useDispatch, useSelector } from "react-redux"
import {
  RootState,
  store,
  updateAppDomain,
  updateUserAddressOnChain,
} from "../../../../rx.core"
import { useDisconnect, useSwitchChain } from "@tria-sdk/connect"
import { TickIcon } from "../icons"
interface NetworkItem {
  chainName: string
  logo: string
  type?: string
}

interface Props {
  network: NetworkItem[]
  onClick: () => void
}

interface TriaWalletStore {
  triaName: string
  userAddress: string
  appDomain: string
  darkMode: boolean
  logo: string
  accessToken: string | null
}

const Chains: React.FC<Props> = ({ network, onClick: handlePopupClose }) => {
  const [appDomain, setAppDomain] = useState<string>()
  const [appLogo, setAppLogo] = useState<string>()
  const [allowedChains, setAllowedChains] = useState<Array<string>>()
  const [activeChain, setActiveChain] = useState<NetworkItem>()
  const [nonEvmChains, setNonEvmChains] = useState([])
  const [testnets, setTestNets] = useState<NetworkItem[]>([])
  const [mainnets, setMainNets] = useState<NetworkItem[]>([])
  const { getAddressByChainName } = useTriaUser()
  const dispatch = useDispatch()
  const { disconnect } = useDisconnect()
  const { switchChain } = useSwitchChain()
  const triaName = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.triaName
  )
  const [disconnected, setDisconnected] = useState<boolean>(false)
  const AAnetworks = useSelector(
    (store: RootState) => store.Constants.AANetworks
  )
  const showTestNets = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.showTestNets
  )
  const handleChainClick = async (triaName: string, chainName: string) => {
    try {
      const response = await getAddressByChainName(triaName, chainName)
      let type = "EOA"
      AAnetworks.forEach((network) => {
        if (network?.chainName?.toUpperCase() === chainName?.toUpperCase()) {
          type = "AA"
        }
      })
      const status = {
        triaName: triaName,
        chainName: chainName,
        address: response,
        type: type,
      }
      switchChain(chainName)
      handlePopupClose()
      dispatch(updateUserAddressOnChain(status))
    } catch (error) {
      console.error(error)
    }
  }
  const userStatus = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  )

  useEffect(() => {
    const result = network.find(
      (network: any) => network?.chainName === userStatus?.chainName
    )
    setActiveChain(result)
  }, [userStatus?.chainName])

  const fetchDataFromLocalStorage = (): TriaWalletStore | null => {
    const data = localStorage.getItem("tria.wallet.store.app")
    return data ? JSON.parse(data) : null
  }

  const handleDisconnect = () => {
    const walletData = fetchDataFromLocalStorage()

    if (walletData && walletData.appDomain) {
      localStorage.clear()
      disconnect()
      dispatch(updateAppDomain())
      setDisconnected(true)
    } else {
      console.error("Invalid app domain")
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setAppDomain(parsedData?.appDomain)
      setAppLogo(parsedData?.logo)
      setAllowedChains(parsedData?.supportedChains)
      setNonEvmChains(parsedData?.userNonEvmAddress)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])
  useEffect(() => {
    const test: NetworkItem[] = []
    const main: NetworkItem[] = []
    network.map((item: NetworkItem) => {
      if (item.type === "testnet") {
        test.push(item)
      } else {
        main.push(item)
      }
    })
    setTestNets(test)
    setMainNets(main)
  }, [network])
  // useEffect(() => {
  //   console.log("non evm chains --->", nonEvmChains)
  // }, [nonEvmChains])

  return (
    <div className='w-[38vh] px-[2vh] h-[35.7vh] rounded-[2vh]  flex-col justify-start items-center inline-flex overflow-hidden'>
      <div
        className='w-full py-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor
          hover:dark:bg-hoverDarkColor hover:bg-hoverColor justify-center items-center gap-[1vh] inline-flex cursor-pointer border-b-[0.3px] border-b-[#202020] '
        onClick={handleDisconnect}
      >
        <div className='w-full h-[4.76vh] justify-start items-center gap-[1vh] flex '>
          <div className='h-[4.76vh] w-[4.76vh] rounded-[2.4vh]'>
            {appLogo && (
              <img
                src={appLogo}
                alt='lo'
                className='h-[4.76vh] w-[4.76vh] rounded-[2.4vh]'
              />
            )}
          </div>
          <div className='flex flex-col self-stretch items-start justify-center w-[16.7vh]'>
            {!disconnected && (
              <div className='flex w-[26.2vh] self-stretch justify-between'>
                <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[1.67vh] font-[600] leading-[120%]'>
                  connected
                </p>
                <p className='text-[1.4vh] text-redWarning font-[600] leading-[120%] ml-[1vh'>
                  disconnect
                </p>
              </div>
            )}
            <div className='flex w-[26.2vh] max-w-[26.2vh]'>
              <p className='text-fontExtraLightColor  text-[1.4vh] font-[600]'>
                {appDomain}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='overflow-auto mx-[-2vh] flex flex-col items-center'>
        {mainnets?.map((data) => (
          <>
            {(!allowedChains || allowedChains?.includes(data?.chainName)) && (
              <div
                className={`${
                  userStatus?.chainName === data?.chainName
                    ? "dark:bg-[#191919] border-[0.3vh] border-[#40404077] "
                    : "dark:bg-primaryDarkColor hover:dark:bg-hoverDarkColor"
                } w-full py-[1.4vh] rounded-[1vh] bg-primaryColor  hover:bg-hoverColor justify-center items-center gap-[1vh] inline-flex cursor-pointer px-[2vh]`}
                onClick={() => {
                  handleChainClick(triaName || "", data?.chainName)
                }}
              >
                <div className='grow shrink basis-0 relative h-[4.76vh] justify-start items-center gap-[1vh] flex '>
                  <div className='w-[4.76vh] h-[4.76vh] relative rounded-[1vh] '>
                    <img
                      className='w-[4.76vh] rounded-[1.1vh] overflow-hidden h-[4.76vh] left-0 top-0 absolute '
                      src={data?.logo}
                    />
                  </div>
                  <div className='w-[25.2vh] self-stretch justify-start items-center gap-[1vh] flex'>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-medium  leading-tight'>
                      {data?.chainName}
                    </div>
                  </div>
                  {/* {userStatus?.chainName === data?.chainName && (
                    <div className='absolute right-[0.8vh]'>
                      <TickIcon />
                    </div>
                  )} */}
                </div>
              </div>
            )}
          </>
        ))}
        {mainnets?.map((data) => (
          <>
            {allowedChains && !allowedChains?.includes(data?.chainName) && (
              <div className='w-full px-[2vh] py-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor hover:dark:bg-hoverDarkColor hover:bg-hoverColor justify-center items-center gap-[1vh] opacity-30 inline-flex cursor-not-allowed'>
                <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1vh] flex'>
                  <div className='w-[4.76vh] h-[4.76vh] relative rounded-[1vh]'>
                    <img
                      className='w-[4.76vh] rounded-[1.1vh] overflow-hidden h-[4.76vh] left-0 top-0 absolute'
                      src={data?.logo}
                    />
                  </div>
                  <div className='w-[25.2vh] self-stretch justify-start items-center gap-[1vh] flex'>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-medium  leading-tight'>
                      {data?.chainName}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
        {showTestNets && (
          <div className='flex flex-col'>
            <div className='flex w-[96%] justify-between items-center '>
              <hr className='border-t w-[26vh] border-fontLightColor dark:border-fontLightColorDark' />
              <p className='flex text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark font-[500]'>
                TESTNETS
              </p>
            </div>
            {testnets?.map((data) => (
              <>
                <div
                  className={`${
                    userStatus?.chainName === data?.chainName
                      ? "dark:bg-[#191919] border-[0.3vh] border-[#40404077] "
                      : "dark:bg-primaryDarkColor hover:dark:bg-hoverDarkColor"
                  } w-full py-[1.4vh] rounded-[1vh] bg-primaryColor  hover:bg-hoverColor justify-center items-center gap-[1vh] inline-flex cursor-pointer px-[2vh]`}
                  onClick={() => {
                    handleChainClick(triaName || "", data?.chainName)
                  }}
                >
                  <div className='grow shrink basis-0 relative h-[4.76vh] justify-start items-center gap-[1vh] flex '>
                    <div className='w-[4.76vh] h-[4.76vh] relative rounded-[1vh] '>
                      <img
                        className='w-[4.76vh] rounded-[1.1vh] overflow-hidden h-[4.76vh] left-0 top-0 absolute '
                        src={data?.logo}
                      />
                    </div>
                    <div className='w-[25.2vh] self-stretch justify-start items-center gap-[1vh] flex'>
                      <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-medium  leading-tight'>
                        {data?.chainName}
                      </div>
                    </div>
                    {/* {userStatus?.chainName === data?.chainName && (
                    <div className='absolute right-[0.8vh]'>
                      <TickIcon />
                    </div>
                  )} */}
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Chains
