import { Crypto, DisplayCost, Footer, Nav } from "../../package/ui.common"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store, updateCurrentRoute } from "../../package/rx.core"
import "../../index.css"
import OpenSocialQr from "../../package/ui.common/src/components/Cards/DisplayCostCard/OpenSocialQr"
import { useEffect, useState } from "react"
import useFetchUserPrefs from "../../hooks/useFetchUserPrefs"
function Home() {
  const dispatch = useDispatch()
  dispatch(updateCurrentRoute(window.location.pathname))
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const socialLoggedIn = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.socialLogin
  )
  useEffect(() => {
    if (!socialLoggedIn && process.env.REACT_APP_ENVIRONMENT === "prod")
      setShowBanner(true)
  }, [socialLoggedIn])
  useEffect(() => {
    const loggedIn = localStorage.getItem("socialLoggedIn")
    if (!loggedIn) {
      setShowBanner(true)
    }
  }, [])
  useFetchUserPrefs()
  return (
    <>
      {
        <div
          className='flex items-center w-[53vh] px-[1.4vh] h-[100vh] overflow-hidden justify-center relative'
          id='home'
        >
          <div className='w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex rounded-[1.4vh] font-montserrat'>
            <div className='w-[100%] grow shrink basis-0 flex-col justify-start relative items-center flex'>
              <Nav />
              <div className='self-stretch'>
                <DisplayCost />
              </div>
              {showBanner && <OpenSocialQr />}
              <Crypto />
            </div>
          </div>
          <div className='absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]'>
            <Footer />
          </div>
          <div className='absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0'>
            <Footer />
          </div>
        </div>
      }
    </>
  )
}

export default Home
