import { useEffect, useState } from "react"
import {
  SearchBuyTokenResponse,
  TokenData,
  coerceStringValue,
  useDebounce,
  useToast,
} from "../../../../rx.core"
import { useTriaUser } from "../../contexts"
import { useSelector } from "react-redux"
import { store, RootState } from "../../../../rx.core"
import { SearchedTokenContainer } from "../../components"

interface Props {
  onShowingResultChange?: (value: boolean) => void
}

export const SearchCryptoLayout: React.FC<Props> = ({
  onShowingResultChange,
}) => {
  const { searchBuyToken } = useTriaUser()
  const [searchValue, setSearchValue] = useDebounce<string>("", handleSearch)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tokenResults, setTokenResults] = useState<SearchBuyTokenResponse>({
    currentChain: [],
    otherChain: [],
  })
  const [resultOnCurrentChain, setResultOnCurrentChain] = useState<TokenData[]>(
    []
  )
  const [resultOnOtherChain, setResultOnOtherChain] = useState<TokenData[]>([])
  const chain = useSelector(
    (store: RootState) => store.User.userStatusOnChain.chainName
  )
  const [showingSearchResult, setShowingSearchResult] = useState<boolean>(false)

  const resetSearchState = () => {
    setSearchValue("")
    setTokenResults({ currentChain: [], otherChain: [] })
    onShowingResultChange && onShowingResultChange(false)
    setShowingSearchResult(false)
  }

  async function handleSearch(newValue: string) {
    if (newValue === "") {
      resetSearchState()
      return
    }

    try {
      setIsLoading(true)
      const searchResult = await searchBuyToken(newValue, chain)
      setTokenResults(searchResult)
      onShowingResultChange && onShowingResultChange(true)
      setShowingSearchResult(true)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      resetSearchState()
    }
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    setResultOnCurrentChain(tokenResults?.currentChain)
    setResultOnOtherChain(tokenResults?.otherChain)
  }, [tokenResults])
  const { getOnRampUrl, getAddressByChainName } = useTriaUser()
  const { setToastData, openToastMessage } = useToast()
  const [currentAddress, setCurrentAddress] = useState("")

  const currentUser = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  )

  useEffect(() => {
    const getUserAddress = async () => {}
    getUserAddress()
  }, [])

  const handleBuyToken = async (token: TokenData) => {
    try {
      const response = await getAddressByChainName(
        currentUser.triaName,
        token?.transak?.network?.toUpperCase()
      )

      const url = `https://global.transak.com/?apiKey=83565d1e-05b6-415a-940c-b02baedd55c8&walletAddress=${response}&cryptoCurrencyCode=${token?.transak?.crypto_name}&network=${token?.transak?.network}`
      window.open(url, "_blank")?.focus()
    } catch (error: any) {
      console.error(error)
    } finally {
    }
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='w-full h-[10vh] justify-start items-center gap-[0.5vh] inline-flex'>
        <div className='grow shrink basis-0 h-[4vh] px-[1.4vh] py-[1vh] dark:bg-hoverDarkColor bg-hoverColor rounded-[3.57vh] justify-start items-center gap-[1.4vh] flex'>
          <div className='flex justify-between items-center self-stretch w-full'>
            <input
              className='dark:bg-hoverDarkColor bg-hoverColor text-fontLightColor text-[1.67vh] dark:text-fontLightColorDark outline-none w-[90%]'
              placeholder='Search Crypto'
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Search Crypto")}
              value={searchValue}
              onChange={handleSearchValueChange}
            ></input>
            <div className='w-[2.1vh] h-[2.1vh] relative'>
              <img src='/icons/search-normal.svg'></img>
            </div>
          </div>
        </div>
      </div>
      {tokenResults && !isLoading && showingSearchResult && (
        <div className='justify-start py-[1.4vh] max-h-[50vh] flex flex-col overflow-y-auto overflow-x-hidden'>
          <div className='self-stretch grid grid-cols-1 w-full'>
            {resultOnOtherChain &&
              resultOnOtherChain.map(
                (token) =>
                  token?.transak && (
                    <SearchedTokenContainer
                      searchToken={token}
                      onClick={() => handleBuyToken(token)}
                    />
                  )
              )}
          </div>
          {!resultOnOtherChain && (
            <div className=''>No tokens with this name</div>
          )}
        </div>
      )}
    </div>
  )
}
