export type Currency = "usd"

export const formatCurrency = (
  value: number | string,
  showTenths: boolean = true,
  currency: Currency = "usd"
) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    maximumFractionDigits: 4,
    minimumFractionDigits: showTenths ? 2 : 0,
    currency,
  })?.format(Number(value))
}

export const formatNumber = (number: number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatNumberWithDecimal = (number: number, decimal: number) => {
  return number ? (number / Math?.pow(10, decimal))?.toPrecision(1) : 0
}

function roundDownSignificantDigits(number: number, decimals: number) {
  return Math?.floor(number * Math?.pow(10, decimals)) / Math?.pow(10, decimals)
}

export const formatNumberDisplay = (
  number: number,
  maximumFractionDigits: number = 10
) => {
  const formatter = new Intl.NumberFormat("en", {
    style: "decimal",
    useGrouping: true,
    maximumFractionDigits: maximumFractionDigits,
  })

  return number
    ? formatter?.format(
        roundDownSignificantDigits(number, maximumFractionDigits)
      )
    : "0"
}

export const formatNumberPercentage = (number: number) =>
  (number / 100)?.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
    signDisplay: "always",
  })

export const formatTimePassed = (date: Date) => {
  const relativeDateFormatter = new Intl.RelativeTimeFormat("en", {
    style: "long",
  })
  const oneHour = 60 * 60 * 1000
  const oneDay = 24 * oneHour
  const oneWeek = 7 * oneDay
  const oneYear = 51 * oneWeek

  const now = Date?.now()
  const difference = now - date?.getTime()

  if (difference < 1000) {
    return relativeDateFormatter?.format(
      -Math?.floor(difference / 1000),
      "seconds"
    )
  }

  if (difference < oneHour) {
    return relativeDateFormatter?.format(
      -Math?.floor(difference / 1000 / 60),
      "minutes"
    )
  }

  if (difference < oneDay) {
    return relativeDateFormatter?.format(
      -Math?.floor(difference / oneHour),
      "hours"
    )
  }

  if (difference < oneWeek) {
    return relativeDateFormatter?.format(
      -Math?.floor(difference / oneDay),
      "days"
    )
  }

  if (difference < oneYear) {
    return relativeDateFormatter?.format(
      -Math?.floor(difference / oneWeek),
      "weeks"
    )
  }

  return relativeDateFormatter?.format(
    -Math?.floor(difference / oneYear),
    "years"
  )
}

export const formatAddress = (address: string) => {
  return address?.slice(0, 5) + "..." + address?.slice(-3)
}
export const formatTriaName = (address: string) => {
  return address?.slice(0, 5) + "....." + address?.slice(-10)
}

export const formatUsernameAddress = (address: string): string => {
  const parts = address?.split(":")

  return parts[0] + ":" + formatAddress(parts[1])
}

export const formatDisplayDate = (date: Date): string =>
  date?.toLocaleDateString("en-us", {
    hour: "numeric",
    minute: "2-digit",
    year: "numeric",
    month: "short",
    day: "2-digit",
  })

export const formatDisplayDateAndTime = (date: Date): [string, string] => {
  const day = date?.getDate()?.toString()?.padStart(2, "0")
  const month = date?.toLocaleString("default", { month: "short" })
  const year = date?.getFullYear()
  const hours = date?.getHours()?.toString()?.padStart(2, "0")
  const minutes = date?.getMinutes()?.toString()?.padStart(2, "0")

  const period = Number(hours) >= 12 ? "PM" : "AM"

  return [`${day} ${month} ${year}`, `${hours}:${minutes} ${period}`]
}

export const formatHistoryDate = (date: Date): string =>
  date?.toLocaleDateString("en-us", {
    hour: "numeric",
    minute: "2-digit",
    month: "numeric",
    day: "numeric",
  })

export const trimText = (text: string, numberOfCharsToKeep: number) => {
  return text?.slice(0, numberOfCharsToKeep)?.concat("...")
}

export const formatStringToLowerCase = (s: string): string => {
  return s?.toLowerCase()
}

export const handleBalance = (a: string | number): number => {
  if (typeof a === "string") {
    return parseFloat(a)
  }
  return a
}
