import { useEffect, useState } from "react"
import SocialQr from "../../Popups/SocialQr"
const OpenSocialQr = () => {
  const [gradientBackground, setGradientBackground] = useState<string>("")
  const [showQr, setShowQr] = useState<boolean>()
  useEffect(() => {
    const theme = localStorage.getItem("tria.wallet.store.app")
    if (theme) {
      const themeObj = JSON.parse(theme)
      const colorFromStorage = themeObj?.primaryColor
      setGradientBackground(colorFromStorage)
    }
  }, [])
  return (
    <>
      <div
        className='w-full h-[10vh] p-[1vh] cursor-pointer hover:scale-105 transform duration-300 '
        onClick={() => setShowQr(true)}
      >
        <div
          className='w-[100%] h-[9.2vh] rounded-[1vh] p-[0.2vh] relative'
          style={{
            background: `linear-gradient(to right, ${gradientBackground}55, #101010, ${gradientBackground}88)`,

            boxSizing: "border-box",
          }}
        >
          <div className='w-[99%] h-[8.8vh] rounded-[1vh] p-[1vh] flex-col justify-center items-center gap-[2vh] flex text-white  overflow-hidden absolute top-[0.2vh] left-[0.2vh] bg-primaryDarkColor' />
          <div
            className='w-[99%] h-[8.8vh] rounded-[1vh] p-[1vh] absolute gap-[2vh] flex text-white overflow-hidden z-30'
            style={{
              background: `linear-gradient(to right, transparent ,${gradientBackground}55)`,
              opacity: 1,
              backgroundRepeat: "no-repeat",
              // backgroundSize: "500% 500%", // or '100% 100%' if you want to stretch the image
              // backgroundPosition: "-90vh -10vh", // shorthand for X and Y
            }}
          >
            <div className='rounded-[1vh] w-[10vh] overflow-hidden flex items-center justify-center'>
              <img src='./icons/tgwhatsapp.svg' />
            </div>
            <div className='flex flex-row items-center justify-center'>
              <p>
                <span className='font-semibold text-[1.6vh] leading-tight'>
                  Connect messaging apps to get updates on your Assets
                </span>{" "}
                <span
                  className='text-[1.6vh] font-bold leading-tight'
                  style={{ color: gradientBackground }}
                >
                  &earn Rewards
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showQr && <SocialQr showQr={showQr} setShowQr={setShowQr} />}
    </>
  )
}
export default OpenSocialQr
