import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ArrowButton } from "../Buttons"
import { formatAddress } from "../../../../rx.core"
import { useFetchAssetHistory } from "../../../../../hooks/useFetchAssetHistory"
interface Props {
  logo: string
  chainName: string
  eoavalueInUsd: number
  aavalueInUsd: number
  setShowHistory: Dispatch<SetStateAction<"All" | "EOA" | "AA">>
  setCurrentAddress: Dispatch<SetStateAction<string>>
}

// Define the type for dropdown options
interface DropdownOption {
  id: number
  label: string
  type: "All" | "AA" | "EOA"
  value: number
}

const AddressDropdown: React.FC<Props> = ({
  logo,
  chainName,
  eoavalueInUsd,
  aavalueInUsd,
  setShowHistory,
  setCurrentAddress,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<DropdownOption>({
    id: 1,
    label: "All",
    type: "All",
    value: 0,
  })

  const [eoaAddress, setEoaAddress] = useState<string>("")
  const [aaAddress, setaaAddress] = useState<string>("")
  useEffect(() => {
    setEoaAddress(
      JSON.parse(localStorage.getItem("tria.wallet.store.app") || "")?.addresses
        ?.evm?.address
    )
    setaaAddress(
      JSON.parse(localStorage.getItem("tria.wallet.store.app") || "")?.addresses
        ?.aa?.address
    )
    setShowHistory("All")
  }, [])
  // Dropdown options
  const options: DropdownOption[] = [
    { id: 1, label: "All", type: "All", value: 0 },
    { id: 2, label: eoaAddress, type: "EOA", value: eoavalueInUsd },
    { id: 3, label: aaAddress, type: "AA", value: aavalueInUsd },
  ]

  // Handle selecting an option
  const handleSelectOption = (optionLabel: DropdownOption) => {
    setSelectedOption(optionLabel)
    setIsOpen(false) // Close the dropdown after selection
    console.log(`Selected: ${optionLabel}`)
    setShowHistory(optionLabel?.type)
    {
      optionLabel?.type === "All"
        ? aavalueInUsd > 0
          ? setCurrentAddress(aaAddress)
          : setCurrentAddress(eoaAddress)
        : optionLabel?.type === "AA"
        ? setCurrentAddress(aaAddress)
        : setCurrentAddress(eoaAddress)
    }
  }

  return (
    <>
      {/* Background Overlay */}
      {isOpen && (
        <div
          className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-0'
          onClick={() => setIsOpen(false)} // Optionally close dropdown when clicking on the background
        ></div>
      )}

      {/* Dropdown */}
      <div className='relative z-10'>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className='px-[2vh] py-[0.5vh] border-[0.1vh] dark:bg-outliningDark border-outlining dark:border-outliningDark text-fontLightColor dark:text-fontLightColorDark focus:outline-none rounded-[2vh] flex gap-[0.5vh] items-center justify-between text-[1.8vh] font-[600]leading-[120%]'
        >
          {selectedOption?.label === "All" ? (
            <div>{selectedOption?.label || "Select an Option"}</div>
          ) : (
            <div className='flex gap-[0.8vh] items-center justify-center'>
              <img
                src={logo}
                alt='chain'
                className='w-[2.4vh] h-[2.4vh] rounded-[0.5vh]'
              />
              <p>
                {formatAddress(selectedOption?.label) || "Select an Option"}
              </p>
              {selectedOption?.type?.toUpperCase() === "AA" && (
                <img
                  src='./images/aaverifiedbatch.svg'
                  alt='aa'
                  className='w-[2.8vh] h-[2.8vh]'
                />
              )}
            </div>
          )}
          <ArrowButton />
        </button>
        {isOpen && (
          <ul className='fixed top-[34vh] left-1/2 transform -translate-x-1/2 w-[38vh]  bg-primaryColor dark:bg-primaryDarkColor  shadow-md rounded-[2vh] px-[1vh] py-[2vh]  text-fontPrimaryColor dark:text-fontPrimaryDarkColor flex flex-col gap-[0.5vh] border-[0.1vh] dark:border-outliningDark border-outlining'>
            {options.map((option) =>
              option?.label === "All" ? (
                <li
                  key={option.id}
                  onClick={() => handleSelectOption(option)}
                  className={`p-[1.4vh] gap-[1vh] rounded-[1vh] ${
                    selectedOption?.label === option?.label
                      ? "border-[0.1vh] dark:border-outliningDark border-outlining bg-hoverColor dark:bg-hoverDarkColor"
                      : ""
                  }   text-[2vh] hover:dark:bg-hoverDarkColor cursor-pointer`}
                >
                  {option.label}
                </li>
              ) : (
                <li
                  key={option.id}
                  onClick={() => handleSelectOption(option)}
                  className={`p-[1.4vh] flex items-center justify-between gap-[1vh] rounded-[1vh] ${
                    selectedOption?.label === option?.label
                      ? "border-[0.1vh] dark:border-outliningDark border-outlining bg-hoverColor dark:bg-hoverDarkColor"
                      : ""
                  }   text-[2vh] hover:dark:bg-hoverDarkColor cursor-pointer`}
                >
                  <div className='flex flex-col'>
                    <p className='text-[2vh] font-semibold leading-normal text-fontPrimaryColor flex dark:text-fontPrimaryDarkColor'>
                      {chainName}
                      {option?.type === "AA" && (
                        <img
                          src='./images/aaverifiedbatch.svg'
                          alt='badge'
                          className='w-[3vh] h-[3vh]'
                        />
                      )}
                    </p>
                    <p className='text-[1.67vh] font-semibold text-fontLightColor flex dark:text-fontLightColorDark '>
                      {" "}
                      ({formatAddress(option.label)})
                    </p>
                  </div>
                  <p className='text-[1.67vh] font-semibold text-fontLightColor flex dark:text-fontLightColorDark '>
                    ${option?.value?.toFixed(2)}
                  </p>
                </li>
              )
            )}
          </ul>
        )}
      </div>
    </>
  )
}

export default AddressDropdown
