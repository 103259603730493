import React from "react"

const ApproveCustomChain = () => {
  return (
    <div className='h-full w-[53vh] p-[2vh]'>
      <div></div>
    </div>
  )
}

export default ApproveCustomChain
