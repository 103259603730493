import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../rx.core"
interface Props {
  showQr?: boolean
  setShowQr: Dispatch<SetStateAction<boolean | undefined>>
}
const SocialQr: React.FC<Props> = ({ showQr, setShowQr }) => {
  const [platform, setPlatform] = useState<"whatsapp" | "TG">("whatsapp")
  const socialLoggedIn = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.socialLogin
  )
  const qrContainerRef = useRef<HTMLDivElement | null>(null) // Ref with type for div element

  // Click event handler with type
  const handleClickOutside = (event: MouseEvent) => {
    if (
      qrContainerRef.current &&
      !qrContainerRef.current.contains(event.target as Node)
    ) {
      setShowQr(false) // Set state to false when clicked outside
    }
  }

  useEffect(() => {
    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  useEffect(() => {
    if (socialLoggedIn) {
      setShowQr(false)
    }
  }, [socialLoggedIn])
  if (!showQr) {
    return null // Or handle the hidden state as needed
  }

  return (
    <div className='h-full w-[53vh] fixed z-[1000] '>
      <div className='w-[53vh] h-full fixed bg-primaryDarkColor opacity-50' />
      <div
        ref={qrContainerRef}
        className='w-[50vh] bg-primaryColor dark:bg-primaryDarkColor  h-auto rounded-[2vh] z-[2000] text-white fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-[2vh] items-center justify-center  pb-[3vh] border-[2px] border-outlining dark:border-outliningDark overflow-hidden'
      >
        {" "}
        <iframe
          src={`${process.env.REACT_APP_AUTH_URL}/gainsqr/${platform}`}
          height={290}
          width={290}
        />
        <div className=' w-full  px-[2vh] flex items-center justify-center gap-[2vh]'>
          <button
            className={`flex w-[1/3] ${
              platform === "whatsapp"
                ? " dark:bg-primaryColor bg-primaryDarkColor dark:text-fontPrimaryColor text-fontPrimaryDarkColor "
                : "bg-[#404040] text-fontPrimaryDarkColor"
            } px-[5vh] rounded-[3vh] py-[1vh] text-[2vh] font-semibold`}
            onClick={() => {
              setPlatform("whatsapp")
            }}
          >
            Whatsapp
          </button>
          <button
            className={`flex w-[1/3] ${
              platform === "TG"
                ? " dark:bg-primaryColor bg-primaryDarkColor dark:text-fontPrimaryColor text-fontPrimaryDarkColor "
                : "bg-[#404040] text-fontPrimaryDarkColor"
            } px-[5vh] rounded-[3vh] py-[1vh] text-[2vh] font-semibold`}
            onClick={() => {
              setPlatform("TG")
            }}
          >
            Telegram
          </button>
        </div>
      </div>
    </div>
  )
}

export default SocialQr
