import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../../contexts"
import {
  RootState,
  store,
  updateSenderAvatar,
  updateUserAvatar,
} from "../../../../rx.core"
import { useEffect, useState } from "react"
import { UserAvatar } from "../Containers/user/UserAvatar"
// import logout from '@tria-sdk/core/controllers/auth.controller'

import ToggleSwitch from "../Buttons/ToggleSwitch"
import { useNavigate } from "react-router-dom"

export const UserMenu: React.FC = () => {
  const { updateAvatar } = useTriaUser()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [accessToken, setAccessToken] = useState<string>()

  useEffect(() => {
    const storedData = localStorage.getItem("tria.accessToken")
    if (storedData !== null) {
      setAccessToken(storedData)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])
  useEffect(() => {}, [accessToken])
  const handleAvatarChangeClick = async () => {
    if (accessToken) {
      try {
        setIsLoading(true)
        const response = await updateAvatar(accessToken)
        if (response?.avatar) {
          dispatch(updateUserAvatar(response))
          dispatch(updateSenderAvatar(response))

          // Notify the parent window that the profile photo has changed
          const message = {
            type: "PROFILE_PHOTO_UPDATED",
            payload: { message: true },
          }
          window.parent.postMessage(message, "*")
        }
      } catch (error) {
        console.error(error)
        const { message } = error as Error
        return Promise.reject(String(message || error))
      } finally {
        setIsLoading(false)
      }
    }
  }
  const customChains = useSelector(
    (store: RootState) => store?.userpref?.customChains
  )
  // const handleLogout = () => {
  //   // logout();
  //   disconnect()
  //   window.open("https://auth-7rin.vercel.app/logout","_self")
  // }
  const handleCustomChainClick = () => {
    navigate("/customchain")
  }
  const handleCustomTokenClick = () => {
    navigate("/customtoken")
  }
  return (
    <div className='flex flex-col w-[38vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2.4vh] overflow-hidden'>
      <div
        className='flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor'
        onClick={handleAvatarChangeClick}
      >
        <div className='w-[26.7vh] flex flex-col items-start justify-start'>
          <p className='flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor '>
            Change Avatar
          </p>
          <p className='flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark'>
            choose your avatar
          </p>
        </div>
        <UserAvatar loading={isLoading} />
      </div>
      <div className='flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor'>
        <div className='w-[26.7vh] flex flex-col items-start justify-start'>
          <p className='flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor '>
            Testnets{" "}
          </p>
          <p className='flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark'>
            enable/disable testnets
          </p>
        </div>
        <ToggleSwitch />
      </div>
      <div
        className='flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor'
        onClick={handleCustomChainClick}
      >
        <div className='w-[26.7vh] flex flex-col items-start justify-start'>
          <p className='flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor '>
            Custom Chains{" "}
          </p>
          <p className='flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark'>
            add or update Custom Chains
          </p>
        </div>
        {/* <ToggleSwitch /> */}
      </div>
      {customChains?.length > 0 && (
        <div
          className='flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor'
          onClick={handleCustomTokenClick}
        >
          <div className='w-[26.7vh] flex flex-col items-start justify-start'>
            <p className='flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor '>
              Custom Tokens{" "}
            </p>
            <p className='flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark'>
              add or update Custom Tokens
            </p>
          </div>
          {/* <ToggleSwitch /> */}
        </div>
      )}
    </div>
  )
}
