import React, { useState } from "react"

const SearchCustomChain = () => {
  const [searchValue, setSearchValue] = useState<string>("")
  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {}
  return (
    <div className='w-full h-[10vh] justify-start items-center gap-[0.5vh] inline-flex'>
      <div className='grow shrink basis-0 h-[4vh] px-[1.4vh] py-[1vh] dark:bg-hoverDarkColor bg-hoverColor rounded-[3.57vh] justify-start items-center gap-[1.4vh] flex'>
        <div className='flex justify-between items-center self-stretch w-full'>
          <input
            className='dark:bg-hoverDarkColor bg-hoverColor text-fontLightColor text-[1.67vh] dark:text-fontLightColorDark outline-none w-[90%]'
            placeholder='Search Custom Chain'
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "Search Custom Chain")}
            value={searchValue}
            onChange={handleSearchValueChange}
          ></input>
          <div className='w-[2.1vh] h-[2.1vh] relative'>
            <img src='/icons/search-normal.svg'></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchCustomChain
