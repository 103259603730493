import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AssetState, AssetValue, FetchParams } from "./asset.model"

const initialState: AssetState = {
  fetchParam: {
    triaName: "",
    chainName: "",
    tokenAddress: "",
    isCustomToken: false,
  },
  assetValue: {
    eoaAssetBalance: 0,
    aaAssetBalance: 0,
    eoavalueInUsd: 0,
    aavalueInUsd: 0,
  },
}

export const assetSlice = createSlice({
  name: "Asset",
  initialState,
  reducers: {
    updateFetchParams: (state, action: PayloadAction<FetchParams>) => {
      state.fetchParam.chainName = action.payload.chainName
      state.fetchParam.tokenAddress = action.payload.tokenAddress
      state.fetchParam.triaName = action.payload.triaName
      state.fetchParam.isCustomToken = action.payload.isCustomToken
    },

    updateAssetBalance: (state, action: PayloadAction<AssetValue>) => {
      state.assetValue.eoaAssetBalance = action.payload.eoaAssetBalance
      state.assetValue.aaAssetBalance = action.payload.aaAssetBalance
      state.assetValue.eoavalueInUsd = action.payload.eoavalueInUsd
      state.assetValue.aavalueInUsd = action.payload.aavalueInUsd
    },
  },
})

export const { updateFetchParams, updateAssetBalance } = assetSlice.actions
