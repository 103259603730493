import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState, updateShowTestNets } from "../../../../rx.core"

const ToggleSwitch = () => {
  const showTestNets = useSelector(
    (store: RootState) => store.AppState?.AppCurrentState?.showTestNets
  )
  const dispatch = useDispatch()
  const toggleSwitch = () => {
    dispatch(updateShowTestNets(!showTestNets))
  }

  return (
    <label className='flex items-center cursor-pointer'>
      <div className='relative'>
        <input
          type='checkbox'
          className='sr-only'
          checked={showTestNets}
          onChange={toggleSwitch}
        />

        <div
          className={`block ${
            showTestNets ? "bg-greenWarning" : "bg-gray-500"
          } w-[5vh] h-[3vh] rounded-full`}
        ></div>
        <div
          className={`absolute left-[0.35vh] top-[0.45vh] bg-white w-[2.2vh] h-[2.2vh] rounded-full transition-transform ${
            showTestNets ? "transform translate-x-[2vh]" : ""
          }`}
        ></div>
      </div>
    </label>
  )
}

export default ToggleSwitch
