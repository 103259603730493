import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NftState, CurrenNftState } from "./nft.model"

const initialState: NftState = {
  currentNft: {
    chainName: "",
    tokenAddress: "",
    tokenId: "",
    triaName: "",
    userAddress: "",
    addressType: "",
    amount: "",
  },
}

export const nftDetailSlice = createSlice({
  name: "Nft",
  initialState,
  reducers: {
    updateCurrentNft: (state, action: PayloadAction<CurrenNftState>) => {
      state.currentNft = action.payload
    },
  },
})

export const { updateCurrentNft } = nftDetailSlice.actions
