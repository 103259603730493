import { useEffect, useState } from "react"
import { SendIcon } from "./SendIcon"
import { WalletIcon } from "./WalletIcon"
import { SwapIcon } from "./SwapIcon"

interface Props {
  content: string
  onClick: () => void
  disabled?: boolean
}

// const { REACT_APP_TRIA_CONFIG_PATH } = process.env as ProcessEnv;

export const SendBuyButtons: React.FC<Props> = ({
  onClick: handleButtonClick,
  content,
  disabled = false,
}) => {
  return (
    <button
      className={`grow shrink basis-0 h-[4.76vh] px-[1.4vh] py-[1vh] rounded-lg ${
        disabled
          ? "bg-[#1515154D] cursor-not-allowed"
          : "bg-[#1515154D] cursor-pointer hover:bg-[#15151580]"
      } justify-center items-center gap-[1.4vh] flex z-20`}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      <div className='text-center text-[2vh] font-semibold  leading-tight text-fontPrimaryDarkColor '>
        {content}
      </div>
      <div className='w-[2.1vh] h-[2.1vh]'>
        <div className='w-[2.1vh] h-[2.1vh]'>
          <div className=' w-full '>
            {content == "Buy" ? (
              <WalletIcon color={"#FFFFFF"} width='2.4vh' height='2.4vh' />
            ) : content == "Send" ? (
              <SendIcon color={"#FFFFFF"} width='2.4vh' height='2.4vh' />
            ) : content == "Swap" ? (
              <SwapIcon color={"#FFFFFF"} width='2.4vh' height='2.4vh' />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </button>
  )
}
