export const assetDetail_initialstate = {
  balanceInTokens: 0,
  balanceInUSD: 0,
  balanceOfTokensInUnits: "",
  chainLogo: "",
  chainName: "",
  decimals: 0,
  isFavorite: false,
  isNativeToken: false,
  isSpam: false,
  logoUrl: "",
  name: "",
  percentChangein24hr: 0,
  quoteRate: 0,
  symbol: "",
  tokenAddress: "",
  wallet: {
    type: "EOA" as "EOA" | "AA",
    address: "",
    name: "",
  },
}

export const nftDetail_initialstate = {
  amount: 0,
  nftInfo: {
    contract_address: "",
    token_id: "",
    interface: "",
    description: "",
    market_data: {
      prices: {
        floor: 0,
      },
    },
    external_links: [
      {
        type: "",
        name: "",
        url: "",
      },
    ],
    name: "",
    tags: [""],
    content: {
      preview: {
        url: "",
      },
      detail: {
        url: "",
      },
      video: {
        url: "",
      },
    },
    attributes: [
      {
        key: "",
        value: "",
      },
    ],
  },
  collectionInfo: {
    name: "",
    description: "",
    content: {
      icon: {
        url: "",
      },
      banner: {
        url: "",
      },
    },
  },
  chainName: "",
  chainLogo: "",
  isFavorite: false,
  contractExplorerUrl: "",
  blurUrl: "",
  openseaUrl: "",
}
