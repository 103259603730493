import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../rx.core"

export type CustomChainDataType = {
  type: "EVM"
  chainId: number
  rpcUrl: string
  currencySymbol: string
  currencyName?: string
  chainName?: string
  chainLogo?: string
  explorerUrl?: string
}

const ChainSelectionDropdown = ({
  setSelectedChainId,
}: {
  setSelectedChainId: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const [selectedOption, setSelectedOption] =
    useState<CustomChainDataType | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const options = useSelector((store: RootState) => store.userpref.customChains)
  const handleSelectChange = (option: CustomChainDataType) => {
    const selectedValue = option // Adjust for default empty option
    setSelectedOption(selectedValue)
    setSelectedChainId(selectedValue?.chainId)
    setIsOpen(false)
  }
  useEffect(() => {
    if (options.length > 0) {
      setSelectedChainId(options[0]?.chainId)
    }
  }, [options])

  return (
    <div className='w-full '>
      {/* Background Overlay */}
      {isOpen && (
        <div
          className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-0'
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Dropdown */}
      <div className='relative z-10 w-full'>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className='px-[2vh] py-[1.4vh] border-[0.1vh] dark:bg-outliningDark border-outlining dark:border-outliningDark text-fontLightColor dark:text-fontLightColorDark focus:outline-none rounded-[2.4vh] flex gap-[0.5vh] items-center justify-between text-[1.8vh] font-[600] leading-[120%] w-full'
        >
          {selectedOption?.chainName ? (
            <div className='flex gap-[0.8vh] items-center justify-center'>
              <img
                src={selectedOption.chainLogo}
                alt='chain'
                className='w-[2.4vh] h-[2.4vh] rounded-[0.5vh]'
              />
              <p>{selectedOption.chainName}</p>
            </div>
          ) : (
            <div className='flex gap-[0.8vh] items-center justify-center'>
              <img
                src={options[0].chainLogo}
                alt='chain'
                className='w-[2.4vh] h-[2.4vh] rounded-[0.5vh]'
              />
              <p>{options[0].chainName}</p>
            </div>
          )}
          <svg
            className={`w-[1.8vh] h-[1.8vh] transform ${
              isOpen ? "rotate-180" : ""
            }`}
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M19 9l-7 7-7-7'
            ></path>
          </svg>
        </button>
        {isOpen && (
          <ul className='fixed top-[50vh] left-1/2 transform -translate-x-1/2  bg-primaryColor dark:bg-primaryDarkColor shadow-md rounded-[2vh] px-[1vh] py-[0.5vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor flex flex-col gap-[0.5vh] border-[0.1vh] dark:border-outliningDark border-outlining w-[46vh]'>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelectChange(option)}
                className={`p-[1.4vh] flex items-center justify-between gap-[1vh] rounded-[1vh] ${
                  selectedOption?.chainName === option.chainName
                    ? "border-[0.1vh] dark:border-outliningDark border-outlining bg-hoverColor dark:bg-hoverDarkColor"
                    : ""
                } text-[2vh] hover:dark:bg-hoverDarkColor cursor-pointer`}
              >
                <div className='flex gap-[1vh] items-center justify-center'>
                  <img
                    src={option.chainLogo}
                    alt={option.chainName}
                    className='w-[2.4vh] h-[2.4vh] rounded-[0.5vh]'
                  />
                  <div className='flex flex-col'>
                    <p className='text-[2vh] font-semibold leading-normal text-fontPrimaryColor flex dark:text-fontPrimaryDarkColor'>
                      {option.chainName}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ChainSelectionDropdown
