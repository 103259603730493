import {
  Asset,
  AssetWithAmounts,
  AssetForTriaName,
  NetworkItem,
  ChainAssets,
} from "../types"
import { SwapTargetResponse } from "@tria-sdk/core"
import { extractColors } from "extract-colors"

export function coerceStringValue(value: string | null | undefined): string {
  return value ?? ""
}

export function reverseString(s: string): string {
  return s.split("").reverse().join("")
}

export function sortNetworks(networks: NetworkItem[]): NetworkItem[] {
  return networks.sort((a: NetworkItem, b: NetworkItem) =>
    a.chainName.localeCompare(b.chainName)
  )
}

export function checkForAssetWithAmounts(asset: Asset | AssetWithAmounts) {
  return (
    typeof (asset as AssetWithAmounts)["amounts"] !== "undefined" &&
    (asset as AssetWithAmounts).amounts.token !== 0
  )
}

export function createAssetId(asset: ChainAssets | AssetForTriaName): string {
  if (asset.tokenAddress) {
    return [asset.chainName, asset.symbol, asset.tokenAddress].join(":")
  } else {
    return [asset.chainName, asset.symbol].join(":")
  }
}

export function convertTokenToAsset(
  token: SwapTargetResponse,
  chainName: string,
  chainLogo: string
): AssetForTriaName {
  return {
    wallet: {
      address: "",
      name: "",
    },
    name: token.name,
    symbol: token.symbol,
    logoUrl: token.logoUrl,
    chainName: chainName,
    chainLogo: chainLogo,
    balanceInTokens: 0,
    balanceOfTokensInUnits: "",
    decimals: 0,
    balanceInUSD: 0,
    quoteRate: 0,
    tokenAddress: token.tokenAddress,
    isNativeToken: false,
    isSpam: false,
    percentChangein24hr: 0,
  } as AssetForTriaName
}
export function sendMessageToParent(
  type: string,
  primaryColor: string,
  secondaryColor: string
) {
  window.parent.postMessage({ type, primaryColor, secondaryColor }, "*")
}
export function sendOverlayMessage(type: string, showOverlay: boolean) {
  window.parent.postMessage({ type, showOverlay }, "*")
}
export const extractImageColor = async (src: string) => {
  const response = await extractColors(src)
  console.log("extracted colors", response)
}
