import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTriaUser } from "../package/ui.common"
import { RootState } from "../package/rx.core"
import {
  updateTotalBalance,
  updatePercentChange,
  updateTotalBalanceLastFetch,
  updateApiTimer,
  updateRefreshLoad,
} from "../package/rx.core/src/redux/features"
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext"

export const useFetchTotalBalance = () => {
  const dispatch = useDispatch()
  const { reload } = useReload()
  const { getTotalBalance } = useTriaUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [triaName, setTriaName] = useState<string>()
  const lastFetchTime = useSelector(
    (state: RootState) => state.User.totalBalanceLastFetch
  )
  const balanceDataExists = useSelector(
    (state: RootState) => state.User.totalBalance !== 0
  )
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  )
  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  )
  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchTotalBalance = async () => {
    const now = new Date()
    if (
      reload ||
      !lastFetchTime ||
      (now.getTime() - new Date(lastFetchTime).getTime() >= 10000 &&
        !userInactive)
    ) {
      try {
        if (!balanceDataExists || reload) {
          setLoading(true)
        }
        if (triaName) {
          const { success, data } = await getTotalBalance(triaName)
          if (success && data) {
            dispatch(updateTotalBalance(data?.balance || 0))
            dispatch(updatePercentChange(data?.percentChangeIn24hr || 0))
            dispatch(updateTotalBalanceLastFetch(new Date().toISOString()))
            dispatch(
              updateApiTimer({
                apiEndpoint: "getTotalBalance",
                time: Date.now(),
              })
            )
          }
        }
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
        dispatch(updateRefreshLoad(false))
      }
    }
  }

  useEffect(() => {
    fetchTotalBalance()
    const intervalId = setInterval(fetchTotalBalance, 10000)
    return () => clearInterval(intervalId)
  }, [triaName, dispatch, lastFetchTime, userInactive])

  useEffect(() => {
    if (reload) {
      fetchTotalBalance()
      setLoading(true)
    }
  }, [reload])
  useEffect(() => {
    if (!refreshLoading && loading) {
      dispatch(updateRefreshLoad(true))
    }
  }, [refreshLoading])
  return { loading }
}
