import { useEffect, useState } from "react"
import React from "react"
import { GetAllAddressesResponse } from "@tria-sdk/core"
import { CopyButton } from "../Buttons"
import QR from "./QR"
import { useSelector } from "react-redux"
import {
  NetworkItem,
  NonEvmAddressesType,
  RootState,
  copyToClipboard,
  formatAddress,
  openToast,
  useToast,
} from "../../../../rx.core"
import { QrButton } from "../Buttons/QrButton"

interface Props {
  addresses: string
  activeChain: NetworkItem | undefined
  nonEvmAddresses?: NonEvmAddressesType[]
}

export const Accounts: React.FC<Props> = ({
  addresses,
  activeChain,
  nonEvmAddresses,
}) => {
  const [showQr, setShowQr] = useState(false)
  const [qrAddress, setQrAddress] = useState("")
  const [qrChain, setQrChain] = useState<NetworkItem>()
  const [qrNetworkItems, setQrNetworkItems] = useState<NetworkItem[]>()
  const [qrLogo, setQrLogo] = useState("")
  const [copied, setIsCopied] = useState(false)
  const [copiedText, setCopiedText] = useState("")
  const { setToastData, openToastMessage, closeToastMessage } = useToast()
  const networkItems = useSelector(
    (store: RootState) => store.Constants?.NetworkItems
  )
  const aaNetworkItems = useSelector(
    (store: RootState) => store.Constants?.AANetworks
  )
  const userStatusOnChain = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  )

  const handleCopyClick = (item: string) => {
    setIsCopied(copyToClipboard(item)?.copied)
    setCopiedText(copyToClipboard(item)?.text || "")
  }
  useEffect(() => {
    if (copied) {
      setToastData({ title: "Copied!", desc: copiedText, status: "message" })
      openToastMessage()
      setIsCopied(false)
      setTimeout(() => closeToastMessage(), 4000)
    }
  }, [copied])
  const handleQrClick = (
    address: string,
    qrChain?: NetworkItem,
    qrItems?: NetworkItem[],
    qrLogo?: string
  ) => {
    setShowQr(!showQr)
    setQrAddress(address)
    setQrChain(qrChain)
    setQrNetworkItems(qrItems)
    if (qrLogo) setQrLogo(qrLogo)
  }
  return (
    <div
      className={`min-w-full bg-primaryColor dark:bg-primaryDarkColor ${
        showQr
          ? "h-[53.5vh] transform duration-200"
          : nonEvmAddresses && nonEvmAddresses?.length > 0
          ? "h-[21vh]"
          : "h-[12vh]"
      } rounded-[2vh] `}
    >
      {!showQr && (
        <div className='w-full h-[7.6vh] px-[2.4vh] py-[1.4vh]  flex-col justify-start items-center gap-[1vh] inline-flex min-w-full  relative'>
          <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
            <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold  leading-tight'>
                  {activeChain?.chainName}
                </div>
              </div>
              <div className='text-center text-neutral-400 text-[1.67vh] font-semibold leading-tight max-w-[24vh] overflow-hidden'>
                {formatAddress(addresses)}
              </div>
            </div>
            <div
              className='w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer'
              onClick={() =>
                handleQrClick(addresses, activeChain, networkItems)
              }
            >
              <QrButton />
            </div>
            <CopyButton
              onClick={() => handleCopyClick(addresses)}
              height='2.1vh'
              width='2.1vh'
            />
          </div>
          {copied && (
            <div className='absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]'>
              Copied
            </div>
          )}
          <div className='flex gap-[0.5vh] absolute left-[2.4vh] top-[7.1vh]'>
            {networkItems?.slice(0, 9)?.map((item: any, index: number) => (
              <div key={index} className=''>
                <img
                  src={item?.logo}
                  alt=''
                  className='w-[2.8vh] h-[2.8vh] rounded-[0.6vh]'
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!showQr &&
        nonEvmAddresses?.map((nonevmaddress) => (
          <div
            className={`w-full mt-[2vh] h-[7.6vh] px-[2.4vh] py-[1.4vh]  flex-col justify-start items-center gap-[1vh] inline-flex min-w-full  relative ${
              userStatusOnChain?.type !== "AA" ? "opacity-40" : ""
            }`}
          >
            <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
                <div className='self-stretch justify-start items-center gap-[0.5vh] inline-flex'>
                  {nonevmaddress?.chainName?.toUpperCase() === "AA" ? (
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold  leading-tight flex items-center justify-center gap-[0.3vh]'>
                      <p>{"Smart Wallet"}</p>

                      <img
                        src='./images/aaverifiedbatch.svg'
                        alt='AA'
                        className='w-[3.4vh] h-[3.4vh]'
                      />
                    </div>
                  ) : (
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold  leading-tight'>
                      {nonevmaddress?.chainName}
                    </div>
                  )}
                </div>
                {nonevmaddress?.address && (
                  <div className='text-center text-neutral-400 text-[1.67vh] font-semibold leading-tight max-w-[24vh] overflow-hidden mt-[-0.8vh]'>
                    {formatAddress(nonevmaddress?.address)}
                  </div>
                )}
              </div>
              <div
                className='w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer'
                onClick={() =>
                  handleQrClick(
                    nonevmaddress?.address,
                    networkItems?.find(
                      (item) =>
                        nonevmaddress?.chainName?.toUpperCase() ===
                        item?.chainName?.toUpperCase()
                    ),
                    undefined,
                    networkItems.find(
                      (item) =>
                        nonevmaddress?.chainName?.toUpperCase() ===
                        item?.chainName?.toUpperCase()
                    )?.logo
                  )
                }
              >
                <QrButton />
              </div>
              {userStatusOnChain?.type === "AA" ? (
                <CopyButton
                  onClick={() => handleCopyClick(nonevmaddress?.address)}
                  height='2.1vh'
                  width='2.1vh'
                />
              ) : (
                <CopyButton
                  onClick={() => {
                    setToastData({
                      title: "Current Chain does not Support Smart Wallet!",
                      desc: "Current Chain does not Support Smart Wallet",
                      status: "error",
                    })
                    openToastMessage()
                    setTimeout(() => closeToastMessage(), 4000)
                  }}
                  height='2.1vh'
                  width='2.1vh'
                />
              )}
            </div>
            {copied && (
              <div className='absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]'>
                Copied
              </div>
            )}
            {nonevmaddress?.chainName?.toUpperCase() === "AA" ? (
              <div className='flex gap-[0.5vh] absolute left-[2.4vh] top-[7.1vh]'>
                {aaNetworkItems
                  ?.slice(0, 9)
                  ?.map((item: any, index: number) => (
                    <div key={index} className=''>
                      <img
                        src={item?.logo}
                        alt=''
                        className='w-[2.8vh] h-[2.8vh] rounded-[0.6vh]'
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className='flex gap-[0.5vh] absolute left-[2.4vh] top-[7.1vh]'>
                {networkItems?.find(
                  (item) =>
                    nonevmaddress?.chainName?.toUpperCase() ===
                    item?.chainName?.toUpperCase()
                ) && (
                  <div className=''>
                    <img
                      src={
                        networkItems.find(
                          (item) =>
                            nonevmaddress?.chainName?.toUpperCase() ===
                            item?.chainName?.toUpperCase()
                        )?.logo
                      }
                      alt=''
                      className='w-[2.8vh] h-[2.8vh] rounded-[0.6vh]'
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      {showQr && (
        <div
          className={` shadow-2xl absolute  flex bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh]  z-50 ${
            showQr ? "" : "hidden"
          }`}
        >
          <QR
            item={qrAddress}
            activeChain={qrChain}
            networkItems={qrNetworkItems || []}
            logo={qrLogo}
          />
        </div>
      )}
      {/*  */}
    </div>
  )
}

export default Accounts
